import { View, Text, Alert, Platform } from 'react-native'
import React from 'react'
import { SafeAreaView, Linking } from 'react-native'
import { StyleSheet } from 'react-native'
import Header from '../component/home/Header'
import Post from '../component/home/Post'
import { ScrollView } from 'react-native'
import BottomTabs from '../component/home/BottomTabs'
import { Image } from 'react-native'
import { TouchableOpacity } from 'react-native'
import { Divider } from 'react-native-elements'
import { useState, useEffect } from 'react'
import firebase from '../firebase'
import { useRoute } from '@react-navigation/native'
import Ionicons from '@expo/vector-icons/Ionicons';

const alertPolyfill = (title, description, options, extra) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'))
 
    if (result) {
        const confirmOption = options.find(({ style }) => style !== 'cancel')
        confirmOption && confirmOption.onPress()
    } else {
        const cancelOption = options.find(({ style }) => style === 'cancel')
        cancelOption && cancelOption.onPress()
    }
 }
 
 const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

const ProfileScreen = ( {navigation} ) => {
    const [posts, setPosts] = useState([])
    const [username, setUsername]= useState([])
    const [picture, setPicture]= useState([])
    const [bio, setBio]= useState([])
    const [link, setLink]= useState([])
    const [sub, setSub]= useState('')
    const [show, setShow]= useState(false)
    const [cost, setCost]= useState('')
    const [isVerified, setIsVerified]= useState(null)
    const [isPaid, setIsPaid]= useState(null)
    const [cred, setCred]=useState('Credits')
    const [credits,setCredits]= useState(null)
    const [planMax, setPlanMax]=useState(null)
    const [subCost, setSubCost]= useState(null)
    const [time,setTime]=useState('')
    const [amount, setAmount]= useState(0)
    const email = useRoute().params.email
    const [earnings, setEarnings]= useState(null)
    const [get, setGet]=useState(null)
    const [creditsout, setCreditsOut]= useState(null)

    useEffect(() => {
        // const uid = navigation.getParam()
        // console.log(uid)
    //    if(uid=== firebase.auth().currentUser.email){
       
    //    }else{
    //     const uid= email
    //    }
  
        
      firebase.firestore().collection('users').doc(email).onSnapshot((doc) => {
        const username =[]
        username.push(doc.data().username)
        setUsername(username)
        
        const earnings= doc.data().earnings
        setEarnings(earnings)

        const picture =[]
        picture.push(doc.data().profile_picture)
        setPicture(picture)

        const bio =[]
        bio.push(doc.data().bio)
        setBio(bio)


        const link =[]
        link.push(doc.data().link)
        setLink(link)
    
        const cost = doc.data().cost
        setCost(cost)

        const isVerified = doc.data().isVerified
        setIsVerified(isVerified)

        const isPaid  = doc.data().isPaid
        setIsPaid(isPaid)

        const get = doc.data().earnings
        setGet(get)
        
        
 
    })
    // try{
    //      firebase.firestore().collection('payees').doc(firebase.auth().currentUser.email).set({earnings:get})
    // }catch{
    //     console.log('not set up')
    // }

    firebase.firestore()
    .collection("users")
    .doc(firebase.auth().currentUser.email).onSnapshot((doc) => {
        const credit =doc.data().credits
        const creditout = doc.data().creditsout
        setCreditsOut(creditout)
        setCredits(credit- creditout)

        const pos = doc.data().planMax
        setPlanMax(pos)

        const sut = doc.data().subCost
        setSubCost(sut)

    })

    firebase.firestore()
    .collection("users")
    .doc(firebase.auth().currentUser.email)
    .collection("userFollowing")
    .doc(email).onSnapshot((doc)=>{
        if(doc.exists){
            const amount = doc.data().amount
            setAmount(amount)
            const time = doc.data().date
            setTime(time)
            console.log(time.seconds, 'this is the time')
            console.log(new Date().getTime()/1000, 'this is the date')
        //     const now = new Date();
        //         console.log(Math.abs(now.getTime()))
        // const msBetweenDates = Math.abs(time.getTime() - now.getTime());
        // const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);

        //     if (daysBetweenDates < 30) {
        //         console.log('date is within 30 days');
        //         } else {
        //             console.log('date is NOT within 30 days');
        //             }
        }else{
            setAmount(cost)

        }
    })


    firebase.firestore()
    .collection("users")
    .doc(firebase.auth().currentUser.email)
    .collection("userFollowing")
    .doc(email)
    .onSnapshot((doc) => {
        if (doc.exists) {
            setSub('Subscribed')
            setShow(true)
            }else if (firebase.auth().currentUser.email === email){
                setShow(true)
                
            }else{
                setSub('Subscribe')
            }
       
  })
//   if (sub==='Subscribed'){
//     firebase.firestore().collection('users').doc(firebase.auth().currentUser.email).collection('userFollowing').doc(email).onSnapshot((doc)=>{
//         const amount=doc.data().amount
//         setAmount(amount)
//     })
//   }

            firebase.firestore().collection('users').doc(email).collection('posts').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
           
                 setPosts(snapshot.docs.map(doc => doc.data()))
        
            //   if(show===false){
            //     console.log(show)
            //     setPosts([])
            //   }
            
           
            
        })
        // firebase.firestore()
        // .collection("users")
        // .doc(firebase.auth().currentUser.email).collection('userFollowing').doc(email).onSnapshot((doc) => {
        //     if (doc.exists){
        //         const date= doc.data().date
        //    setTime(date)
        //     }else{
        //         console.log('oo')
        //     }
            
            
        // })
        if(isPaid===false){
            setCred('')
        }else{
            if(cost==='1'){
                setCred('Credit')
            }else{
            console.log('g')}
        }
        console.log(credits)
    }, [credits])
    const  follow = () => {
        if(sub === 'Subscribe'){
            onFollow()
        }else{
            onUnfollow()
        }
    }
    const onFollow = () => {
     
        if(isPaid === false){
            const dt= new Date()
            const ddt= dt.setDate(dt.getDate() + 5)
            console.log(cost, 'hdghsh')
            firebase.firestore()
            .collection("users")
            .doc(firebase.auth().currentUser.email)
            .collection("userFollowing")
            .doc(email)
            .set({
                date:  new Date(),
                amount: 0,
               
            })
            
          
            firebase.firestore().collection('users').doc(email).collection('Followers').doc(firebase.auth().currentUser.email).set({})
            setShow(true)
            console.log(cost)
        }else{
            // alert(
            //     "Help",
            //     "For help visit our website below",
            //     [
            //       {
            //         text: "Cancel",
            //         onPress: () => console.log("Cancel Pressed"),
            //         style: "cancel"
            //       },
            //       { text: "Go to Site", onPress: () =>  Linking.openURL('https://www.reddit.com/r/fitvault/') }
            //     ]
            //   );
            alert(
                "Do you wish to subscribe?",
                "By accepting you agree to spend credit(s) and you cannot unsubscribe for five days.",
                [
                  {
                    text: "Cancel",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                    
                  },
                  { text: "Accept", onPress: () => {
                   
                    if(credits-cost<0){
                        
                        alert(
                            "Not enough credits",
                            "Upgrade your plan in the Payment Information tab on our website version of the app",
                            [

                              {
                                text: "Cancel",
                                onPress: () => console.log("Cancel Pressed"),
                                style: "cancel"
                              },
                              { text: "Visit Website", onPress: () => {
                                if (Platform.OS !== 'web'){
                                    Linking.openURL('https://thefitvault.com')
                                }else{
                                    navigation.navigate('Gate')
                                }
                              } }
                            ]
                          )
                    
                    }else{
                        const dt= new Date()
                         const ddt= dt.setDate(dt.getDate() + 5)
                        console.log(dt)
                    firebase.firestore()
                    .collection("users")
                    .doc(firebase.auth().currentUser.email)
                    .collection("userFollowing")
                    .doc(email)
                    .set({
                        date:  new Date(ddt),
                        amount: parseInt(cost),
                        })
                      
                    firebase.firestore().collection('users').doc(email).collection('Followers').doc(firebase.auth().currentUser.email).set({  amount: parseInt(cost)})
                    setShow(true)
                    console.log(cost)

                    firebase.firestore().collection('users').doc(email).update({
                        earnings: earnings+parseInt(cost)
                    })
                    firebase.firestore()
                    .collection("users")
                    .doc(firebase.auth().currentUser.email)
                    .update({
                        creditsout: parseInt(creditsout)+parseInt(cost)
                       })
                  }
                } }
                ]
              );
        }
       
        
    }

    const onUnfollow = () => {
       
        // console.log(time)
        // firebase.firestore()
        // .collection("users")
        // .doc(firebase.auth().currentUser.email)
        // .collection("userFollowing")
        // .doc(email).onSnapshot((doc) => {
        //     console.log(doc.data().amount)})
        if(time.seconds > new Date().getTime()/1000){
          
            const leng = new Date(time*1000)
            alert(
                "Cannot Unsubscribe",
                `It has not been a month since you first subscribed. Your unsubscribe date is ${leng}`,
                [

                  { text: "OK", onPress: () => console.log("OK Pressed") }
                ]
              );
        }else{
        
        

     firebase.firestore()
        .collection("users")
        .doc(firebase.auth().currentUser.email)
        .collection("userFollowing")
        .doc(email)
        .delete()
        
        firebase.firestore()
        .collection("users")
        .doc(firebase.auth().currentUser.email)
        .update({
            creditsout: creditsout- parseInt(amount)
           })

           firebase.firestore().collection('users').doc(email).update({
            earnings: earnings-parseInt(amount)
        })
        // if(credits>planMax){
        //     firebase.firestore()
        //     .collection("users")
        //     .doc(firebase.auth().currentUser.email)
        //     .update({
        //         credits: planMax
        //        })
        // }
        firebase.firestore()
        .collection("users")
        .doc(email)
        .collection("Followers")
        .doc(firebase.auth().currentUser.email)
        .delete()

    }
    }



  return (
        <SafeAreaView style={styles.container}>
            
            <Header navigation={navigation}/>
          
          <ScrollView>
            <View style={{backgroundColor:'#fafafa'}}>
            <View style={styles.screencontainer}>
             <TouchableOpacity onLongPress={()=>{
                alert(
                    "Report User",
                    "Are you sure you wish to report user? This action cannot be undone.",
                    [
                      {
                        text: "Cancel",
                        onPress: () => console.log("Cancel Pressed"),
                        style: "cancel"
                      },
                      { text: "REPORT", onPress: () => console.log("OK Pressed") }
                    ]
                  );
              
             }}><Image source= {{uri : picture[0]}} style={styles.story} /></TouchableOpacity>
                 
                <View >
                    {isVerified===true ? (
                        <View style={{flexDirection:'row'}}>
                                <Text style={{fontSize:20, fontWeight:'bold', padding:10, paddingLeft:25}}>{username[0]}</Text>  
                                <Ionicons style={{paddingVertical:5}}name="checkmark-done-circle" size={30} color='orange'></Ionicons>
                        </View>

                         
                    ):(
                        <Text style={{fontSize:20, fontWeight:'bold', padding:10}}>{username[0]}</Text>  
                        
                    )}
                    
                     
                </View>
                
            </View> 
            <View style={styles.list}>
                {/* <View style={{alignItems:'center', padding:5, paddingHorizontal:30}}>
                    <Text style={{ padding:5}}>4</Text>
                    <Text style={{ padding:5}}>Plans</Text>
                </View> */}

                {/* <View style={{alignItems:'center', padding:5, paddingHorizontal:30}}>
                    <Text style={{ padding:5}}>4500</Text>
                    <Text style={{ padding:5}}>Subscribers</Text>
                </View> */}

                

            </View>
            <View style={{ backgroundColor: "white",}}>

           
            <View style={styles.bio}>
                <Text style={{color: 'black', padding:15, textAlign:'left'}}>{bio[0]}</Text>
            </View>
            <TouchableOpacity onPress={() => Linking.openURL(link[0])}>
                <Text style={{color:'blue', textAlign:'center', paddingTop:10, paddingBottom:15}}>{link[0]}</Text>
            </TouchableOpacity>

            {email !== firebase.auth().currentUser.email  ? (
                 <TouchableOpacity style={styles.button} onPress={() =>{
                    follow()
                 } }>
                 <Text style={{color:'white', padding:15, fontSize:20}}>{sub} for {cost} {cred} </Text>
             </TouchableOpacity>
            ):
             (<TouchableOpacity style={styles.button}  onPress={() => navigation.navigate('NewPostScreen')}>
             <Text style={{color:'white', padding:15, fontSize:20}}>Post</Text>
         </TouchableOpacity>
            )}
            

            </View>
           
            

            <Divider style={{marginTop:20}}/>
            </View>
            
            {/* {show === true ? (
                    <Text></Text>
                ):(
                    <Text style={{paddingVertical:15000}}>SubScri</Text>
                )} */}
            {posts.map((post, index, shown)=>(
                <Post post= {post} key= {index} shown={shown} navigation={navigation}/>    
            ))}
           
          </ScrollView>
          
        
          
          <BottomTabs navigation={navigation}/>
      </SafeAreaView>
      
    )
  }
  const styles= StyleSheet.create({
      container: {
          flex: 1,
          backgroundColor: "white",
      },
      screencontainer: {
          backgroundColor: "white",
          flex: 1,
          alignItems:'center',
          
      },
      story: {
        marginTop:15,
        width: 70,
        height: 70,
        borderRadius: 50,
        marginLeft: 6,
        borderWidth: 3,
        borderColor: "#ff8501",
    },
    button:{
        backgroundColor:'orange',
        marginHorizontal: 25,
        alignItems:'center',
        borderRadius:10,
    },
    list: {
        flexDirection:'row',
        justifyContent:'center',
        backgroundColor: "white",
    },
    bio: {
        backgroundColor: "white",
        margin:10,
        borderColor:'orange',
        borderWidth: 1,
        borderRadius:10,
       
    }
  })
export default ProfileScreen
import { View, Text, ScrollView, StyleSheet } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native'
import Header from '../component/home/Header'
import BottomTabs from '../component/home/BottomTabs'
import { useRoute } from '@react-navigation/native'
import firebase from '../firebase'
import UserBlip from '../component/home/UserBlip'
import { Divider } from 'react-native-elements'

const FollowingScreen = ({ navigation }) => {
    const email = useRoute().params.email
    const [follow, setFollow] = useState([])
    const [followers, setFollowers] = useState([])
    const [num,setNum]= useState('')
    const [num1,setNum1]= useState('')
    useEffect(()=> {
        firebase.firestore()
        .collection('users')
        .doc(firebase.auth().currentUser.email)
        .collection('userFollowing').get().then((querySnapshot) => {
            const follow = querySnapshot.docs.map((doc) => {
              return (doc.id)
            })
            console.log(follow)
            setFollow(follow)
            setNum1(follow.length)})

        firebase.firestore()
        .collection('users')
        .doc(firebase.auth().currentUser.email)
        .collection('Followers').get().then((querySnapshot) => {
            const followers = querySnapshot.docs.map((doc) => {
              return (doc.id)
            })
            setFollowers(followers)
          setNum(followers.length)})
            
    },[])
  return (
    <SafeAreaView style={styles.container}>
          
    <Header navigation={navigation}/>
  
  <ScrollView>
  <Text style={{fontSize:18, padding:10, fontWeight:'bold'}}>Following({num1}):</Text>
    <Divider/>
    {follow.map((name, index)=>(
        <UserBlip name={name} key={index} navigation={navigation} />
    ))}
      <Divider style={{marginTop:20, }}/>
   <Text style={{fontSize:18, padding:10, fontWeight:'bold'}}>Followers({num}):</Text>
    <Divider/>
  {followers.map((name, index)=>(
        <UserBlip navigation={navigation} name= {name}key={index}/>
    ))}


  </ScrollView>
  
  
  <BottomTabs navigation={navigation}/>
</SafeAreaView>
  )
}
const styles= StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
    },
    screencontainer: {
        backgroundColor: "white",
        flex: 1,
    },
})
export default FollowingScreen
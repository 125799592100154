import { View, Text } from 'react-native'
import React from 'react'
import { SafeAreaView } from 'react-native'
import Workout from '../component/home/Workout'
import { useRoute } from '@react-navigation/native'

const WorkoutScreen = ({navigation}) => {
  const name = useRoute().params.name
  const email = useRoute().params.email
  const workoutId = useRoute().params.workoutId
  console.log(email, name, workoutId, 'tjis the na,e')
  return (
    <SafeAreaView style={{backgroundColor:'white'}}>
    <Workout navigation={navigation} name={name} email={email} workoutId={workoutId}/>
   </SafeAreaView>
  )
}

export default WorkoutScreen
import { View, Text , TouchableOpacity} from 'react-native'
import React from 'react'
import { StyleSheet } from 'react-native'
import { Linking } from 'react-native'

const Exercise = ({exercise}) => {
    
  return (
    <View>
    <View style={{backgroundColor:'white', paddingVertical:10}}>
         <TouchableOpacity onPress={() => Linking.openURL(exercise.link).catch(error => {
   console.error(error);
})
         }style={styles.click}>
          <View style={{flexDirection:'row', margin:10, borderWidth:1, backgroundColor:'orange', borderColor:'orange',borderRadius:10}}>
            <Text style={styles.title}>{exercise.tag}</Text>
            <Text style={styles.title}>{exercise.ename}</Text>

          </View>
          <View style={{flexDirection:'row', justifyContent:'center'}}>
            <Text style={styles.titlesec}>{exercise.reps}</Text>
            <Text style={styles.titlesec}>{exercise.rest} rest</Text>
         
          </View>
        </TouchableOpacity>
    </View>
    </View>
  )
}
const styles = StyleSheet.create({
    container: {
        backgroundColor:'white'
        
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent:'space-between',
        alignItems: 'center',
        padding: 10,
        borderBottomColor: 'lightgray',
        borderBottomWidth: 1,
    },
    headerText:{
        fontWeight: 'bold',
        marginRight: 25,
       

    },
    click:{
          backgroundColor:'lightgray',
    marginHorizontal: 25,
    borderRadius:10,
   
        
    },
    title:{
        fontSize:18,
        padding:15,
        color:'white',
        fontWeight:'bold'

        
    },
    header:{
        fontSize:15,
        padding:20,
        color: 'white',
        textAlign:'center'

        
    },
    titlesec:{
      color:'black',
      textAlign:'center',
      padding:10,
      paddingTop:0,
    }
})
export default Exercise
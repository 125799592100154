import { View, Text, Alert, Platform } from 'react-native'
import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons';
import { ScrollView } from 'react-native';
import { useRoute } from '@react-navigation/native'
import  firebase from '../../firebase'
import Exercise from './Exercise';
import { usePreventScreenCapture, addScreenshotListener } from 'expo-screen-capture';
 
const Workout = ({navigation}) => {
  const name = useRoute().params.name
  const email = useRoute().params.email
  const workoutId = useRoute().params.workoutId
  console.log(name, 'this is the name')
  if(Platform.OS !== 'web'){
 usePreventScreenCapture()
  addScreenshotListener(()=>{
    //might need persmissions to do this if apple doesnt let through
    Alert.alert(
      "WARNING",
      "Do not screenshot. Your account is flagged and will be banned if this continues",
      [

        { text: "OK", onPress: () => console.log("OK Pressed") }
      ]
    );
  })
  }else{
    
  }
 
  const [exercises, setExercises] = useState([])
    useEffect(()=>{
        firebase.firestore().collection('users').doc(email).collection('posts').doc(workoutId).collection('workoutNames').doc(name).collection("exercises").orderBy('createdAt')
        .onSnapshot(snapshot => {
          setExercises(snapshot.docs.map(doc => doc.data()))
         
        })
       
    }, [])
 
  return (
   
    <View style={styles.container}>
 
            <Header navigation={navigation} name={name}/>
            <ScrollView>
            {exercises.map((exercise, index)=>(
                <Exercise exercise= {exercise} key= {index} navigation={navigation}/>    
            ))}
                 
                  {email !== firebase.auth().currentUser.email  ? (
               
               <View></View>
           ):
                <View style={{paddingVertical:50}}>
                    <TouchableOpacity style={{margin:15, borderRadius:30,alignItems:'center', backgroundColor: 'orange'}}
                    onPress={() => navigation.navigate('NewExerciseScreen', {name:name, email:email, workoutId:workoutId})}>
                     
                      <Text style={{color:'white' ,padding:10 ,fontSize:15}}>Add Exercise</Text>
                     
 
                     
                    </TouchableOpacity>
                    {/* <TouchableOpacity onPress={()=>{
                      Alert.alert(
                        "Help",
                        "For help visit our website below",
                        [
                          {
                            text: "Cancel",
                            onPress: () => console.log("Cancel Pressed"),
                            style: "cancel"
                          },
                          { text: "Clear All", onPress: () =>  {
                            firebase.firestore().collection('users').doc(email).collection('posts').doc(workoutId).collection('workoutNames').doc(name).collection("exercises").set({})
                          } }
                        ]
                      );
                    }}>
                      <Text>Click to Clear Workout</Text>
                    </TouchableOpacity>
  */}
                  </View>
}
<View style={{paddingBottom:500}}></View>
       </ScrollView>
        </View>
      )
    }
    const Header = ( {navigation, name}) => (
   
        <View style={styles.headerContainer}>
                <TouchableOpacity>
                    <Ionicons name="chevron-back-outline" size={30} color='black' onPress={()=> navigation.goBack()}></Ionicons>
                </TouchableOpacity>
           
               
                <Text style={styles.headerText}>{name}</Text>
                <Text/>
        </View>
    )
   
  const styles = StyleSheet.create({
        container: {
            backgroundColor:'white'
           
        },
        headerContainer: {
            flexDirection: 'row',
            justifyContent:'space-between',
            alignItems: 'center',
            padding: 10,
            borderBottomColor: 'lightgray',
            borderBottomWidth: 1,
        },
        headerText:{
            fontWeight: 'bold',
            marginRight: 25,
           
   
        },
        click:{
              backgroundColor:'lightgray',
        marginHorizontal: 25,
        borderRadius:10,
       
           
        },
        title:{
            fontSize:18,
            padding:15,
            color:'white',
            fontWeight:'bold'
 
           
        },
        header:{
            fontSize:15,
            padding:20,
            color: 'white',
            textAlign:'center'
 
           
        },
        titlesec:{
          color:'black',
          textAlign:'center',
          padding:10,
          paddingTop:0,
        }
    })
 
export default Workout

import { View, Text } from "react-native";
import { StatusBar } from "react-native";
import HomeScreen from "./screens/HomeScreen";
import NewPostScreen from "./screens/NewPostScreen";
import SignedInStack from "./navigation";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import AuthNavigation from "./AuthNavigation";
import TermsAndConditions from "./component/home/Terms";

export default function App() {
  return (<AuthNavigation/>)
}
import { View, Text , Alert, Linking} from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native'
import { StyleSheet } from 'react-native'
import Header from '../component/home/Header'
import { ScrollView } from 'react-native'
import BottomTabs from '../component/home/BottomTabs'
import { TouchableOpacity } from 'react-native'
import firebase from '../firebase'
import { Platform } from 'react-native'

const alertPolyfill = (title, description, options, extra) => {
   const result = window.confirm([title, description].filter(Boolean).join('\n'))

   if (result) {
       const confirmOption = options.find(({ style }) => style !== 'cancel')
       confirmOption && confirmOption.onPress()
   } else {
       const cancelOption = options.find(({ style }) => style === 'cancel')
       cancelOption && cancelOption.onPress()
   }
}

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

const handleSignout = async () => {
try{
   await firebase.auth().signOut().then(() => console.log('signed out')) 
   
}catch(error){
   console.log(error)
}
}

   
const SettingsScreen = ({navigation}) => {
   const [credits,setCredits]= useState('')
   useEffect(()=>{
    
      firebase.firestore().collection('users').doc(firebase.auth().currentUser.email).onSnapshot((doc) => {
         const credit =doc.data().credits
         const creditsout= doc.data().creditsout
         setCredits(credit- creditsout)
      })
 
   },[])
  return (
    <SafeAreaView style={styles.container}>
          
    <Header navigation={navigation}/>
  
  <ScrollView>
  <View style={{borderBottomColor:"lightgray", borderBottomWidth:1}}>
        <Text style={styles.header}>Account Settings</Text>
     </View>
     <TouchableOpacity style={styles.click} onPress={handleSignout}>
       
        <Text style={styles.title}>Sign Out</Text>
        
     </TouchableOpacity>
     <TouchableOpacity style={styles.click} onPress={()=> navigation.navigate('EditProfileScreen')} >
        <Text style={styles.title}>Edit Profile</Text>
     </TouchableOpacity>
   

     <TouchableOpacity style={styles.click} onPress={()=> {
       alert(
         "Request Verification",
         "Visit the link below to request verification.",
         [
           {
             text: "Cancel",
             onPress: () => console.log("Cancel Pressed"),
             style: "cancel"
           },
           { text: "Go to Site", onPress: () =>  Linking.openURL('https://thefitvault.com') }
         ]
       );
     }} >
        <Text style={styles.title}>Request Verfication</Text>
     </TouchableOpacity>
     <TouchableOpacity style={styles.click} onPress={()=>{
          alert(
            "Report User or Users Content",
            "Press and hold on users profile picture on their account page, or post title to report it and it will be reviewed by FitVault withing 24hrs. You can also report harmful content on our help page down below. ",
            [
              {
                text: "Cancel",
                onPress: () => console.log("Cancel Pressed"),
                style: "cancel"
              },
              { text: "Go to Site", onPress: () =>  Linking.openURL('https://www.reddit.com/r/fitvault/') }
            ]
          );
     }}>
        <Text style={styles.title}>Report User or Users Content</Text>
     </TouchableOpacity>
     <TouchableOpacity style={styles.click} onPress={()=>{
          alert(
            "Delete Account",
            "Are your sure you want to delete your account. Your account will be deleted in 3-5 business days.",
            [
              {
                text: "Cancel",
                onPress: () => console.log("Cancel Pressed"),
                style: "cancel"
              },
              { text: "Delete", onPress: async () =>  {
               try{
                  await firebase.auth().signOut().then(() => console.log('signed out')) 
                  
               }catch(error){
                  console.log(error)
               }

              } }
            ]
          );
     }}>
        <Text style={styles.title}>Delete Account</Text>
        </TouchableOpacity>
     <TouchableOpacity style={styles.click} onPress={()=>{
          alert(
            "Help",
            "For help visit our website below",
            [
              {
                text: "Cancel",
                onPress: () => console.log("Cancel Pressed"),
                style: "cancel"
              },
              { text: "Go to Site", onPress: () =>  Linking.openURL('https://www.reddit.com/r/fitvault/') }
            ]
          );
     }}>
        <Text style={styles.title}>Help</Text>
     </TouchableOpacity>
    

     <View style={{borderBottomColor:"lightgray", borderBottomWidth:1}}>
        <Text style={styles.header}>Payment Settings</Text>
     </View>
  
     <TouchableOpacity style={styles.click} onPress={()=> navigation.navigate('RecieveScreen')}>
        <Text style={styles.title}>Recieve Payments</Text>
     </TouchableOpacity>
     {/* <TouchableOpacity style={styles.click}>
        <Text style={styles.title}>Invoices</Text>
     </TouchableOpacity> */}
     <TouchableOpacity style={styles.click} onPress={()=> {
       if (Platform.OS !== 'web'){
         alert('Adding Credits', 'To add credits visit use our web version, login and hit Add Credits in settings', [
            {text: 'Cancel', onPress: () =>  console.log('cancel pressed')},
            {text: 'Visit Website', onPress: () =>  {Linking.openURL('https://thefitvault.com/')}},
          ]);
        
       }else{
         navigation.navigate('Gate')
       }
     } }>
        <Text style={styles.title}>Add Credits</Text>
     </TouchableOpacity>
     {/* <TouchableOpacity style={styles.click}>
        <Text style={styles.title}>Payment History</Text>
     </TouchableOpacity> */}
     <TouchableOpacity style={styles.click}  onPress={()=> navigation.navigate('ChangeCostScreen')}>
        <Text style={styles.title}>Change Subscription Amount</Text>
     </TouchableOpacity>
     <View style={{borderBottomColor:"lightgray", borderBottomWidth:1, }}>
        <Text style={styles.header}>Available Credits: </Text>
     </View>
     
     
     <View style={styles.click} >
       <Text style={{color:'green',padding:15, textAlign:'left',  paddingLeft:40}}>{credits} Credit(s)</Text>
     </View>
     <View style={{paddingBottom:100}}/>
  </ScrollView>
  

  <BottomTabs navigation={navigation}/>
</SafeAreaView>
  )
}
const styles= StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
    },
    screencontainer: {
        backgroundColor: "white",
        flex: 1,
    },
    click:{
        borderBottomColor:"lightgray",
        borderBottomWidth: 1,
        
    },
    title:{
        fontSize:15,
        padding:15,
        paddingLeft:40
    },
    header:{
        fontSize:15,
        fontWeight:'bold',
        padding:20,
        
    }
})
export default SettingsScreen
import { useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { Button, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import firebase from '../firebase'
import Ionicons from '@expo/vector-icons/Ionicons';

function Checkout({navigation}) {
    const name = useRoute().params.name
    const priceId = useRoute().params.priceId
    const [cost,setCost]=useState(null)

// 
const session= async()=>{
  const docRef = await firebase.firestore()
  .collection('customers')
  .doc(firebase.auth().currentUser.uid)
  .collection('checkout_sessions')
  .add({ 
      automatic_tax: true,
      tax_id_collection: true,
      allow_promotion_codes: true,
      price: priceId,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      metadata: {
        key: 'value',
      },
    
    });
  // Wait for the CheckoutSession to get attached by the extension
  docRef.onSnapshot((snap) => {
    const { error, url } = snap.data();
    if (error) {
      // Show an error to your customer and then inspect your function logs.
      alert(`An error occured: ${error.message}`);
      document.querySelectorAll('button').forEach((b) => (b.disabled = false));
    }
    if (url) {
      window.location.assign(url);
    }
  });
}



useEffect(()=>{
    if(name==='starter'){
        setCost('4.99')
    } if(name==='basic'){
      setCost('9.98')
  } if(name==='standard'){
    setCost('14.97')
} if(name==='silver'){
  setCost('19.96')
} if(name==='gold'){
  setCost('24.95')
} if(name==='platinum'){
  setCost('29.94')
} if(name==='elite'){
  setCost('34.93')
} if(name==='ultra'){
  setCost('39.92')
}
},[])



  return (
    <View>
      <View style={styles.headerContainer}>
            <TouchableOpacity>
                <Ionicons name="chevron-back-outline" size={30} color='black' onPress={()=> navigation.goBack()}></Ionicons>
            </TouchableOpacity>
  
            
            <Text style={styles.headerText}></Text>
            <Text/>
    </View>
    
    <View style={styles.card}>
        <Text style={{paddingTop:'10px', fontSize:'15px'}}>{name.toUpperCase()} PLAN</Text>
        <Text style={{paddingTop:'10px', fontSize:'15px'}} class="price">{cost}</Text>
        <TouchableOpacity style={{marginTop:'10px' ,paddingHorizontal:'50px', backgroundColor:'orange', borderRadius:'30px'}} onPress={session}>
            <Text style={{padding:'10px', fontSize:'15px'}}>Checkout</Text>
        </TouchableOpacity>
    </View>

    </View>
   
  )
}

const styles= StyleSheet.create({
card:{
    marginVertical: '100px',
    marginHorizontal: '10px',
    padding: '10px',
    textAlign: 'center',
    boxShadow: '0 5px 15px #00000029',
    borderRadius: '15px',
    marginTop: '30px',
},
    headerContainer:{
        flexDirection: 'row',
        justifyContent:'space-between',
        alignItems: 'center',
        padding: 10,
        borderBottomColor: 'lightgray',
        borderBottomWidth: 1,
        backgroundColor:'#fafafafa'
    }
})
export default Checkout
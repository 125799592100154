import { View, Text, Image, Alert } from 'react-native'
import React, { useEffect, useState } from 'react'
import { StyleSheet, TouchableOpacity, TextInput, ScrollView, Button } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons';
import firebase from '../firebase'
import * as Yup from 'yup'
import { Formik } from 'formik';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as ImagePicker from 'expo-image-picker';
import { Divider } from 'react-native-elements';



const uploadPostSchema = Yup.object().shape({
  bio: Yup.string().max(2200, 'Caption has reached character limit'),
  plan: Yup.string().max(50,'Plan has reached character limit'),
  workoutName: Yup.string().max(50,'Workout name has reached character limit')
})
const EditProfileScreen = ({navigation}) => {
  const [bio, setBio] = useState('')
  const [link, setLink] = useState('')
  const [profile, setProfile] = useState('')
  const [image, setImage] = useState(null);
  const [save, setSave] = useState('Save')
  const [cost, setCost] = useState('')
 const [much, setMuch] = useState('')
 const [expand, setExpand]= useState(false)

  useEffect(()=> {
      firebase.firestore().collection('users').doc(firebase.auth().currentUser.email).onSnapshot(doc=>{
        const bio = doc.data().bio
        setBio(bio)

        const link = doc.data().link
        setLink(link)
        
        const profile = doc.data().profile_picture
        setProfile(profile)
        const much = doc.data().cost
        setMuch(much)
      } )
  },[])

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    const image = result.uri
    const path = `${firebase.auth().currentUser.email}/${Math.random().toString(36)}`
    console.log(path)
    setSave('Saving...')
    const response = await fetch(image)
    const blob = await response.blob();

    const task  = firebase
    .storage()
    .ref()
    .child(path)
    .put(blob)
console.log('we in dis thing')
const taskProgress = snapshot => {
    console.log(`transferred: ${snapshot.bytesTransferred}`)
}

const taskCompleted = () => {
    task.snapshot.ref.getDownloadURL().then((snapshot) =>{
        const profile = snapshot
        setProfile(profile)
        setSave('Saved')
    })
}

const taskError = snapshot => {
    console.log(snapshot)
}

task.on("state_changed", taskProgress, taskError, taskCompleted)
  };

  const uploadPostToFirebase = (bio, link) => {
    
    firebase.firestore()
    .collection('users')
    .doc(firebase.auth().currentUser.email)
    .update({
     
      bio: bio,
      link: link,
      profile_picture: profile,
       
    }).then(()=> navigation.goBack())
    
  }
  

  
    
  return (
    
    <SafeAreaView>
       
        <Header navigation={navigation}/> 
        <ScrollView>
        <View style={{alignItems:'center', paddingBottom:10}}>
      <Image
        style={{
          marginTop:15,
          width: 100,
          height: 100,
          borderRadius: 50,
          marginLeft: 6,
          borderWidth: 3,
          borderColor: "#ff8501",}}
        source={{
          uri: profile,
        }}
      />
      </View>
        <View style={{ flex: 1, margin:20,flexDirection: 'row' }}>
          <View>

         
        <Text style={{fontSize:18, fontWeight:'bold', paddingBottom:5, paddingLeft:5}}>Edit Profile Picture</Text>
        <View style={{paddingBottom:0}}>
          <TouchableOpacity onPress={
          pickImage
        
        }>
            <Text style={{color:'gray', marginLeft:5, padding:5, fontSize:15 }}>Pick an image from camera roll</Text>
          </TouchableOpacity>
      
        <View style={{alignItems:'center'}}>
      </View>
       
      </View>

   
      {/* <Button title="Pick an image from camera roll" onPress={pickImage} />
      <View style={{
        alignItems:'center'}}>
     <Image source={{ uri: image }} style={{ width: 200, height: 200 }} /></View>
      <Button title={save} onPress={() => uploadStorage(image)}/> */} 
      </View>
    
    </View>
        <Formik
        enableReinitialize= {true}
    initialValues={{bio: bio, link: link, profile_picture:profile}}
    onSubmit={(values) => {
      uploadPostToFirebase(values.bio, values.link, values.profile_picture)
    }}
    validationSchema={uploadPostSchema}
    validateOnMount={true}>
 {({ handleBlur, handleChange, handleSubmit, values, errors, isValid, push, remove}) => 
        
        <View style={{ margin: 20, justifyContent: 'space-between' }}>
            
        <Text style={{fontSize:18, fontWeight:'bold', paddingBottom:5}}>Edit Bio</Text>
        <TextInput 
         multiline={true}
     
        style={{fontSize: 20, marginBottom:50, padding:10, borderWidth:1, borderColor:'lightgray', borderRadius:10}} 
        placeholder= 'New Bio' 
        placeholderTextColor= 'black'
        onChangeText={handleChange('bio')}
        onBlur={handleBlur('bio')}
        value={values.bio}/>
        <Text style={{fontSize:18, fontWeight:'bold', paddingBottom:5}}>Edit Link</Text>
      <TextInput 
        style={{fontSize: 20, marginBottom:20, padding:10, borderWidth:1, borderColor:'lightgray', borderRadius:10}} 
        placeholder= 'New Link' 
        placeholderTextColor= 'black'
        onChangeText={handleChange('link')}
        onBlur={handleBlur('link')}
        value={values.link}/>

         <TouchableOpacity  style={{backgroundColor:'orange', marginTop:40,borderRadius:30, margin : 20,}} onPress= {handleSubmit} title="Update" disabled={!isValid}>
<Text style={{ textAlign:'center',fontSize:18, padding:15,color:'white'}}>Update</Text>
         </TouchableOpacity>
       

</View>
}
    </Formik>

    
        <Text style={{padding:500}}></Text></ScrollView>
    </SafeAreaView>
  )
}
const Header = ( {navigation}) => (
  <View style={styles.headerContainer}>
          <TouchableOpacity>
              <Ionicons name="chevron-back-outline" size={30} color='black' onPress={()=> navigation.goBack()}></Ionicons>
          </TouchableOpacity>

          
          <Text style={styles.headerText}>Edit Profile</Text>
          <Text/>
  </View>
)

const styles = StyleSheet.create({
  container: {
      marginHorizontal: 10,
      
  },
  headerContainer: {
      flexDirection: 'row',
      justifyContent:'space-between',
      alignItems: 'center',
      padding: 10,
      borderBottomColor: 'lightgray',
      borderBottomWidth: 1,
  },
  headerText:{
      fontWeight: 'bold',
     marginRight: 25,
     

  }
})

export default EditProfileScreen
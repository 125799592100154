import { View, Text, Image } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native'
import { StyleSheet } from 'react-native'
import Header from '../component/home/Header'
import Post from '../component/home/Post'
import { ScrollView } from 'react-native'
import { StatusBar } from 'expo-status-bar'
import BottomTabs from '../component/home/BottomTabs'
import  firebase  from '../firebase'
import UserBlip from '../component/home/UserBlip'
import TermsAndConditions from '../component/home/Terms'




const HomeScreen = ( {navigation} ) => {

    const [follow, setFollow] = useState([])
    const [creditsout, setCredout] = useState(null)
    const [terms, setTerms] = useState(true)
    useEffect(()=> {
       
        firebase.firestore()
        .collection('topCreators')
        .get().then((querySnapshot) => {
            const follow = querySnapshot.docs.map((doc) => {
              return (doc.id)
            })
            console.log(follow)
            setFollow(follow)

            firebase.firestore().collection('users')
            .doc(firebase.auth().currentUser.email).onSnapshot((doc)=>{
                const creditsout= doc.data().creditsout
                setCredout(creditsout)
                const terms= doc.data().terms
                setTerms(terms)
                console.log(terms)

            firebase.firestore().collection('customers')
            .doc(firebase.auth().currentUser.uid)
            .collection('subscriptions')
           .where('status', 'in', ['trialing', 'active'])
            .onSnapshot(async (snapshot) => {
             if (snapshot.empty){
                firebase.firestore().collection('users')
                         .doc(firebase.auth().currentUser.email).update({
                            credits: 0
                           })
             }else{
                const doc = snapshot.docs[0];
                const subid= doc.id
                console.log(subid, 'yappatappa')
                firebase.firestore().collection('customers')
                .doc(firebase.auth().currentUser.uid)
                .collection('subscriptions').doc(subid).onSnapshot((doc) => {
                     const role=  doc.data().role

                     if (role==='starter'){
                        firebase.firestore().collection('users')
                         .doc(firebase.auth().currentUser.email).update({
                            credits: 1 
                           })
                     }if(role==='basic'){
                        firebase.firestore().collection('users')
                        .doc(firebase.auth().currentUser.email).update({
                           credits: 2
                          })
                     }if(role==='standard'){
                        firebase.firestore().collection('users')
                        .doc(firebase.auth().currentUser.email).update({
                           credits: 3
                          })
                    }if(role==='silver'){
                        firebase.firestore().collection('users')
                        .doc(firebase.auth().currentUser.email).update({
                           credits: 4
                          })
                    }if(role==='gold'){
                        firebase.firestore().collection('users')
                        .doc(firebase.auth().currentUser.email).update({
                           credits: 5 
                          })
                    }if(role==='platinum'){
                        firebase.firestore().collection('users')
                        .doc(firebase.auth().currentUser.email).update({
                           credits: 6 
                          })
                    }if(role==='elite'){
                        firebase.firestore().collection('users')
                        .doc(firebase.auth().currentUser.email).update({
                           credits: 7
                          })
                    }if(role==='ultra'){
                        firebase.firestore().collection('users')
                        .doc(firebase.auth().currentUser.email).update({
                           credits: 8 
                          })
                     }else{
                        console.log('fuckyou880')
                     }
                     })
             }})})
              })
        },[])
    // const [posts, setPosts] = useState([])
    // const [followingEmail, setFollowingEmail] = useState([])
    // const [x, setX] = useState('')
    // const [newI, setNewI] = useState([])

    // useEffect( ()=>{

    //     firebase.firestore().collection('users').doc(firebase.auth().currentUser.email).collection('userFollowing').get().then((querySnapshot) => {
    //         const followingEmail = querySnapshot.docs.map((doc) => {
    //           return (doc.id)
    //         })
    //         setFollowingEmail(followingEmail)
    //         console.log(followingEmail, 'here is the followinglist', followingEmail.length)
    //         let i = 0
    //         while (i < followingEmail.length){
    //                     firebase.firestore().collection('users')
    //                     .doc(followingEmail[i]).collection('posts').onSnapshot(snapshot => {
    //                         const newI = snapshot.docs.map(doc => doc.data())
    //                         setPosts([...posts,...newI]) 
    //                     }).then(()=>
    //                       console.log(posts,'dfsaf'),
    //                     ).catch((error)=> {
    //                         console.log(error)
    //                     })
    //                     i++
                        
                    
    //                 }
    //     })
    // //     firebase.firestore()
    // //     .collection('users')
    // //     .doc(firebase.auth().currentUser.email)
    // //     .collection('userFollowing').get().then((querySnapshot) => {
    // //         const followingEmail = querySnapshot.docs.map((doc) => {
    // //           return (doc.id)
    // //         })
    // //         setFollowingEmail(followingEmail)
    // //         console.log(followingEmail, 'here is the followinglist', followingEmail.length)
        
           
    // //     }).then(() => {
    // //       console.log(followingEmail.length,'this x')
    // //     let i = 0
    // //     while (i < followingEmail.length){
    // //         console.log(i, 'this is i')
    // //         firebase.firestore().collection('users')
    // //         .doc(followingEmail[i]).collection('posts').onSnapshot(snapshot => {
    // //             const newI = snapshot.docs.map(doc => doc.data())
    // //             setPosts([...posts,...newI]) 
    // //         }).then(()=>
    // //           console.log(posts,'dfsaf'),
        
    // //         i++
    // //         )
        
    // //     }
    // // }).catch((error)=> {
    // //     console.error(error)
    // // }
    // // )
       
    // }, []) 
  





  return (
   
      <SafeAreaView style={styles.container}>
           {terms=== true ?(

         <>
        <Header navigation={navigation}/>
      
        <ScrollView>   
        
            {/* {posts.map((post, index)=>(
                <Post post= {post} key= {index} navigation={navigation}/>    
            ))} */}
            
            <Text style={{fontSize:32, padding:15,paddingBottom:10, fontWeight:'bold'}}>Trending Creators</Text>
            <Text style={{padding:5, textAlign:'left', paddingHorizontal:25}}>Here are some of the fastest growing creators on the app.</Text>

            
            <View style={{alignItems:'center'}}>
               <View style={{ marginVertical:15, width:'80%', borderWidth:1, borderBottomWidth:1,borderColor:"lightgray"}}>
               {follow.map((name, index)=>(
        <UserBlip name={name} key={index} navigation={navigation} />
    ))}
                </View> 
            </View>
       
        </ScrollView>
        
        <StatusBar style="dark" /> 
        <BottomTabs navigation={navigation}/>
        </>
        ):(
            <View>
                <TermsAndConditions  navigation={navigation}/>
            </View>
            )}
    </SafeAreaView>
    
  )
}
const styles= StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
    },
    screencontainer: {
        backgroundColor: "white",
        flex: 1,
    },
})

export default HomeScreen
import { View, Text, Alert , TextInput} from 'react-native'
import React from 'react'
import { StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native'
import { Formik } from 'formik'
import * as Yup from 'yup'
import firebase from '../../../firebase'
import {db} from '../../../firebase'
import Validator from 'email-validator'

const RegisterForm = ({navigation}) => {
    const RegisterFormSchema = Yup.object().shape({
        email: Yup.string().email().required('An email is required'),
        username: Yup.string().required().min(8,'An email is required'),
        password: Yup.string().required().min(8, 'Password must be 8 characters')
    })
    // const getRandomProfilePicture = async () => {
    //     const response =  await fetch('http://randomuser.me/api')
    //     const data = await response.json()
    //     return data.results[0].picture.large
    // }
    const onRegister = async (email, password, username) => {
        try{
            const authUser = await firebase.auth().createUserWithEmailAndPassword(email, password)
            console.log('user created', email, password)

            firebase.firestore().collection('users').doc(authUser.user.email).set({
                owner_uid: authUser.user.uid,
                username: username,
                email: authUser.user.email,
                profile_picture: "https://firebasestorage.googleapis.com/v0/b/fitvaultt.appspot.com/o/Screenshot-2022-07-20-233011.png?alt=media&token=4f9dc617-e529-4057-b011-57e14f6d84bb",
                bio: 'This is your bio! Tell the world who you are and how you can help them. This is a good time to mention your credentials and what you do the best. You can edit your profile in the settings tab.',
                link: 'https://www.youtube.com/',
                cost: 'Free',
                isVerified: false,
                isPaid: false,
                subCost:0,
                credits:0,
                planMax:0,
                paymentStatus:false,
                earnings:0,
                creditsout:0,
                terms: false
            })
            
             }catch(error){
        Alert.alert(error.message)
    }
}

  return (
    <View>
         <Formik
        initialValues={{email:'', username: '', password:'',}}
        onSubmit={(values) => {
            onRegister(values.email, values.password, values.username)
            console.log(error)
        }}
        validationSchema={RegisterFormSchema}
        validateOnMount={true}>

            {({handleChange, handleBlur, handleSubmit, values, isValid}) => 
            <>
       <View style={[styles.inputs,
    {borderColor: values.username.length < 1 || values.username.length>=8 ? 'black' : "red"}]}>
            <TextInput style={{padding: 15}}placeholder='Username' placeholderTextColor={'black'} onChangeText={handleChange('username')} onBlur={handleBlur('username')} value={values.username}/>
            
        </View>
        <View style={[styles.inputs,
    {borderColor: values.email.length < 1 || Validator.validate(values.email)  ? 'black' : "red"}]}>
            <TextInput style={{padding: 15}}placeholder='Email' placeholderTextColor={'black'} onChangeText={handleChange('email')} onBlur={handleBlur('email')} value={values.email}/>
            
        </View>
        <View style={[styles.inputs,
    {borderColor: values.password.length < 1 || values.password.length>=8 ? 'black' : "red"}]}>
            <TextInput autoCorrect={false} secureTextEntry={true} style={{padding: 15}}placeholder='Password' placeholderTextColor={'black'} onChangeText={handleChange('password')} onBlur={handleBlur('password')} value={values.password}/>
            
        </View>

       <TouchableOpacity onPress={handleSubmit} disabled={!isValid}>
        <View style={styles.button}>
            <Text style={{color:'white', padding:10, fontSize:20}}>Register</Text>
        </View>
        </TouchableOpacity> 
        <TouchableOpacity 
        onPress={() =>navigation.push('LoginScreen')}
        style={{alignItems:'center', paddingTop:25}}>
            <Text style={styles.text}>Click Here To Login</Text>
        </TouchableOpacity>
        <TouchableOpacity 
        onPress={() =>navigation.push('Forgot')}
        style={{alignItems:'center', paddingTop:25}}>
            <Text style={styles.text}>Forgot Password?</Text>
        </TouchableOpacity>
        </>
}
        </Formik>
    </View>
  )
}
const styles = StyleSheet.create({
    container: {

    },
    logo: {
        alignItems:"center",
        paddingTop:100,
        paddingBottom:50,
    },
    inputs: {
        backgroundColor:"#fafafafa",
        marginHorizontal: 30,
        borderColor:"black",
        borderWidth: 1,
        marginBottom:20,
        borderRadius:30
    },
    text:{
        color:"gray",
    },
    button:{
        backgroundColor:'orange',
        alignItems:'center',
        borderRadius:30,
        marginHorizontal:40,
        marginTop:20

    }
    
   
})
export default RegisterForm
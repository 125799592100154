import { View, Text, TouchableOpacity , StyleSheet, Image} from 'react-native'
import React, { useEffect, useState } from 'react'
import firebase from '../../firebase'
import { Divider } from 'react-native-elements'


const UserBlip = ({name, navigation}) => {
    const [username, setUsername]=useState('')
    const [profile, setProfile]=useState('https://firebasestorage.googleapis.com/v0/b/fitvaultt.appspot.com/o/mattduarte56%40gmail.com%2F0.sdwjrrgw93?alt=media&token=bc70396f-2ae4-4951-a7ed-9e497557293a')
 useEffect(()=>{
    firebase.firestore().collection('users').doc(name).onSnapshot(doc=> {
       const username = doc.data().username
        setUsername(username)
        
        const profile = doc.data().profile_picture
        setProfile(profile)
        console.log(profile)
    })
 }, [])
  return (
    <View>

 

        <TouchableOpacity stlye={{color:''}}onPress={() => navigation.push('ProfileScreen',{ email : name})}>  
        <View style={{flexDirection:'row', alignItems:'center', padding:10}}>

            <Image source={{uri: profile}} style={styles.story}/>
            <Text style={{color: "black", marginLeft: 10, fontSize:15}}>
                {username}
            </Text>
           
        </View>
        </TouchableOpacity>
      <Divider/>
     
    </View>
  )
}
const styles= StyleSheet.create({
    story: {
        width: 35,
        height: 35,
        borderRadius: 50,
        marginLeft: 6,
        borderWidth: 2,
        borderColor: "#ff8501",
    }})

export default UserBlip
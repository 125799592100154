import { View, Text } from 'react-native'
import React from 'react'
import * as Yup from 'yup'
import { Formik, Field, FieldArray, Form, ErrorMessage, Grid } from 'formik'
import { useState, useEffect } from 'react'
import { TextInput } from 'react-native'
import { Divider, Input } from 'react-native-elements'
import { Button } from 'react-native-elements'
import firebase from '../../../firebase'






const PLACEHOLDER_IMG = "https://picsum.photos/200"

const uploadPostSchema = Yup.object().shape({
    bio: Yup.string().max(2200, 'Caption has reached character limit'),
    plan: Yup.string().max(50,'Plan has reached character limit'),
    workoutName: Yup.string().max(50,'Workout name has reached character limit')
})


const FormikPostUploader = ({navigation}) => {
  const [username, setUsername]= useState([])
  const [picture, setPicture]= useState([])
  const [usernameitem, setUsernameitem] = useState('')
  const [workoutNames, setWorkoutNames]= useState(['Workouts:'])
  

      useEffect(() => {
        firebase.firestore().collection('users').doc(firebase.auth().currentUser.email).onSnapshot((doc) => {
          const username =[]
          username.push(doc.data().username)
          const user = (username[0])
          setUsername(user)
          
  
          const picture =[]
          picture.push(doc.data().profile_picture)
          const pic = picture[0]
          setPicture(pic)
   
      })
      setUsernameitem(usernameitem)
          
      }, [])

      const funky= (workname) =>{
        setWorkoutNames(workoutNames.concat([workname]))
      }
      const loggy= (id,email,list) =>{
        console.log(id,email,list)
        const x= list.length 
        console.log(i)
        let i= 0
        while(i<x){
          firebase.firestore()
        .collection('users')
        .doc(email).collection('posts').doc(id).collection('workoutNames').doc(list[i])
        .set({
         exercise: [{list:'post', likes:'posts',test:'pot'}]
           
        

        })
          i++
        }
        navigation.goBack()
        
      }
     
      
    const uploadPostToFirebase = (plan, bio, workname) => {
     
      const list= workoutNames.concat(workname)
      const unsubscribe = firebase.firestore()
      const email = firebase.auth().currentUser.email
      const id = (performance.now().toString(36)+Math.random().toString(36))
      // const user = firebase.firestore.collection('users').doc(email)
        firebase.firestore()
        .collection('users')
        .doc(email).collection('posts').doc(id)
        .set({
          plan: plan,
          bio: bio,
          user: username,
          profile_picture: picture,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          workoutId: id,
          email: email,
          workoutNames: workoutNames,
           
        

        })
        .then(() => navigation.goBack()
        )
        

        return unsubscribe
    }
  //   const handleFormChange = (index, event) => {
  //     let data = [...workoutNames];
  //     data[index][event.target.workname] = event.target.value;
  //     setWorkoutNames(data);
  //  }
 
    return (
    <Formik
    initialValues={{bio: '', plan: '', workname:''}}
    onSubmit={(values) => {
      funky(values.workname)
      uploadPostToFirebase(values.plan, values.bio, values.workname)
    }}
    validationSchema={uploadPostSchema}
    validateOnMount={true}>
       
        {({ handleBlur, handleChange, handleSubmit, values, errors, isValid, push, remove}) => 
        <>
        <View style={{ margin: 20, justifyContent: 'space-between' }}>
            
        
        <TextInput 
        style={{fontSize: 20}} 
        placeholder= 'Plan Name' 
        placeholderTextColor= 'black'
        onChangeText={handleChange('plan')}
        onBlur={handleBlur('plan')}
        value={values.plan}/>



        <TextInput 
        style={{fontSize: 20}}
        multiline={true} 
        placeholder= 'Bio' 
        placeholderTextColor= 'black'
        onChangeText={handleChange('bio')}
        onBlur={handleBlur('bio')}
        value={values.bio}/>
        {errors.plan && (
            <Text style={{color:"red", fontSize:15}}>{errors.plan}</Text>
        )}
        <Divider style={{marginTop: 20}}/>

        {/* {workoutNames.map((index) => {
          return (
            <View key={index}>
              <TextInput 
              style={{fontSize: 20, paddingVertical:5}}
        multiline={true} 
        placeholder= 'Workout Name' 
        placeholderTextColor= 'black'
        value={values.workname}
       onChange={handleChange('workoutNames')}
      >

              </TextInput>
              <View>
              <Button title='Add workout' style={{backgroundColor:'orange', marginVertical:10}}
              onPress= {()=>{
                let newfield = {workname: ''}

                setWorkoutNames([...workoutNames, newfield])
              }}></Button>
            

              </View>
            

            </View>
          )
        })} */}
          {/* <Button title='Remove' style={{backgroundColor:'orange', marginTop:15}}
              onPress= {()=>{
                setWorkoutNames([{workname:''}])
              }}></Button> */}

 
                    <View>
                      {/* {workoutNames.map(() => {
                     
                      return (
                        <View>

                        <TextInput 
                        style={{fontSize:20}}
                         placeholderTextColor= 'black'
                         placeholder={`Workout Name`}
                         value={values[workoutNames.toString()]}
                        handleBlur={() => handleBlur('workname')}
                    onChangeText={handleChange('workname')}>

                        </TextInput><Text style={{ paddingTop: 10,color:'red', textAlign:'center'}}>Make sure you enter full workout name before adding new workout</Text>
                        <Button title='Add workout' style={{backgroundColor:'orange', marginVertical:10}}
              onPress= {()=>{
                      const newfield= values.workname
                      setWorkoutNames(workoutNames.concat(newfield))
                      
                    
                      
                
                
                
              }}></Button>
                        </View>
                      )
                      }
                    )} */}
                    </View>

                 

      

        </View>
     
        
          <Text style={{textAlign:'center', paddingBottom:15, fontSize:15,}}>The exercises are added to the workout after posting! Click your post when it is uploaded to add them</Text>
          <Button onPress= {handleSubmit} title="Post" disabled={!isValid}/>
        
        </>
  }


    </Formik>
  )
}

export default FormikPostUploader
import React, { useEffect , useState} from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { View } from 'react-native';
import firebase from '../firebase';
import Ionicons from '@expo/vector-icons/Ionicons';
import Change from './Change';

  
  
  

function Gate({navigation}) {     
    const [status, setStatus]=useState(null)
    const [statu, setStatu]=useState('null')
    const email=firebase.auth().currentUser.uid
    useEffect(()=>{
 
    
      if(firebase.auth().currentUser.uid==="Mmz7npSeqBg2KhlqfPkhs5l82wU2"){
        setStatus(true)
        console.log(statu, 'pfgsgs')
       }else{

      
      
      firebase.firestore().collection('customers')
      .doc(firebase.auth().currentUser.uid)
      .collection('subscriptions')
     .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {
       if (snapshot.empty){
       
         setStatus(false)
         
       }else{
        
       }
     
   // In this implementation we only expect one active or trialing subscription to exist.
     const doc = snapshot.docs[0];
  //    firebase.firestore().collection('customers')
  //    .doc(firebase.auth().currentUser.uid)
  //    .collection('subscriptions').doc(doc.id).onSnapshot((doc) => {
  //   doc.data().canceled_at
  //  })
   });
  }

    }, [])
    if(status===true){
      return(
        <View>
          {/* <Header/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/> */}
        <Text>To earn credits you can participate in activites listed on our social media.</Text>
      </View>
      )
      
    }
    if(status===false){
return (
    <View style={{  flexDirection:'column', textAlign: 'center'}}>
     <View style={styles.headerContainer}>
            <TouchableOpacity>
                <Ionicons name="chevron-back-outline" size={30} color='black' onPress={()=> navigation.goBack()}></Ionicons>
            </TouchableOpacity>
  
            
            <Text style={styles.headerText}></Text>
            <Text/>
    </View>
       <View style={styles.body}>
         
         <Text style={{ fontSize:22, fontWeight:'bold', fontFamily:"sans-serif", color:'orange', textAlign:'left', marginLeft:20}}>Choose Subscription:</Text>
         {/* <Text>Plans are easily adjustable to fit your needs.</Text> */}
       <View style={{padding:15, fontFamily:'sans-serif'}}>
       
 
         <TouchableOpacity  onPress={()=>{navigation.navigate('Checkout', {user:email, name:'starter', priceId:'price_1LmlGqJskFSBmImyFdLJEuRb'})}} style={styles.tr}>
             <View style={{padding:15, flexDirection:'column'}} >
             <Text>Starter Plan (1 Credit)</Text>
             <Text style={styles.cost}>$4.99 USD</Text>
             <Text style={styles.fine}>This is our entry level plan that gives users 1 credit to spend.</Text>
             {/* <button className='but' id='checkout'>Checkout</button> */}
             </View>
         </TouchableOpacity>
         <TouchableOpacity style={styles.tr} onPress={()=>{navigation.navigate('Checkout', {user:email, name:'basic', priceId:'price_1LmlGTJskFSBmImyrRyOL8Zr'})}}>
             <View style={{padding:15, flexDirection:'column'}}>
             <Text style={{width:'100%'}}>Basic Plan (2 Credits)</Text>
             <Text style={styles.cost}>$9.98 USD</Text>
             <Text style={styles.fine}>This is our entry level plan that gives users 2 credits to spend. Most trainers on the app provide there plans for this cost.</Text>
             </View>
         </TouchableOpacity>
 
         <TouchableOpacity style={styles.tr}onPress={()=>{navigation.navigate('Checkout', {user:email, name:'standard', priceId:'price_1LmlG6JskFSBmImyK65oT18o'})}}>
             <View style={{padding:15, flexDirection:'column'}}>
             <Text>Standard Plan (3 Credits)</Text>
             <Text style={styles.cost}>$14.97 USD</Text>
             <Text style={styles.fine}>This is our entry level plan that gives users 3 credits to spend. </Text>
             </View>
         </TouchableOpacity>
 
         <TouchableOpacity onPress={()=>{navigation.navigate('Checkout', {user:email, name:'silver', priceId:'price_1LmlFfJskFSBmImyMZae0Si6'})}}style={styles.tr} >
             <View style={{padding:15, flexDirection:'column',}}>
             <Text>Silver Plan (4 Credits)</Text>
             <Text style={styles.cost}>$19.96 USD</Text>
             <Text style={styles.fine}>This is our entry level plan that gives users 4 credits to spend. This is the most common subscription our users get.</Text>
             </View>
         </TouchableOpacity>
 
         <TouchableOpacity style={styles.tr} onPress={()=>{navigation.navigate('Checkout', {user:email, name:'gold', priceId:'price_1LmlFKJskFSBmImyPROesXkv'})}}>
             <View style={{padding:15, flexDirection:'column'}}>
             <Text>Gold Plan (5 Credits)</Text>
             <Text style={styles.cost}>$24.95 USD</Text>
             <Text style={styles.fine}>This is our entry level plan that gives users 5 credits to spend.</Text>
             </View>
         </TouchableOpacity>
 
         <TouchableOpacity style={styles.tr} onPress={()=>{navigation.navigate('Checkout', {user:email, name:'platinum', priceId:'price_1LmlEWJskFSBmImytpKWZFlt'})}}>
             <View style={{padding:15, flexDirection:'column'}}>
             <Text>Platinum Plan (6 Credits)</Text>
             <Text style={styles.cost}>$29.94 USD</Text>
             <Text style={styles.fine}>This is our entry level plan that gives users 6 credits to spend. </Text>
             </View>
         </TouchableOpacity>
         <TouchableOpacity style={styles.tr} >
             <View style={{padding:15, flexDirection:'column'}}>
             <Text>Elite Plan (7 Credits)</Text>
             <Text style={styles.cost}>$34.93 USD</Text>
             <Text style={styles.fine}>This is our entry level plan that gives users 7 credits to spend.</Text>
             </View>
         </TouchableOpacity>
         <TouchableOpacity id="checkout-button" style={styles.tr} onPress={()=>{navigation.navigate('Checkout', {user:email, name:'ultra', priceId: 'price_1Lml6pJskFSBmImyWfVSCXpS'})}}>
             <View style={{padding:15, flexDirection:'column'}}>
             <Text>Ultra Plan (8 Credits)</Text>
             <Text style={styles.cost}>$39.92 USD</Text>
             <Text style={styles.fine}>This is our entry level plan that gives users 8 credits to spend.</Text>
             </View>
         </TouchableOpacity>
         {/* <View style={styles.tr}onPress={()=>{navigation.navigate('/checkout', {user:email, name:'custom'}})}}>
             <View style={{padding:15, flexDirection:'column'}} className='fff'>
             <Text>Custom Plan (___ Credits)</Text><br/><br/>
             <Text style={styles.cost}>$_____ USD</Text><br/><br/>
             <Text style={styles.fine}>Enter your desired number of credits and have a plan completely tailored to you!</Text>
             </View>
         </View> */}
    
         
 
         {/* <Text>We know that you want access to some of the best minds in fitness, but we also know that it's hard to find the right place.
              That's why we've made it our mission to make sure that our members have everything they need for a successful fitness journey. <br style={{margin:15}}/><br/>
              Our trainers are some of the best and most experienced in the industry, so when you join us at FitVault, 
              you'll be getting nothing short of world-class service every time!
             Are you ready to join our FitVault family? </Text> */}
       </View>
       </View>
       {/* <View onPress={()=>{navigation.navigate('/changeplan', {email:email}})}}> */}
        
       {/* </View> */}
     </View>
  )
    }
    
    else{
         return(
         <View>
            <Change navigation={navigation}/>
         </View>
  )
    }
  
 
}

  
  

const styles= StyleSheet.create({
    body: {
        paddingTop:'20px',
backgroundColor:'#fafafafa'
    },
    tr: {
        margin: '15px',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '0 5px 15px #00000029',
        borderRadius: '15px',
        cursor: 'pointer',
        display: 'block',
    },
    cost: {   
        fontSize: '18px',
        fontWeight: 600,
        color: 'orange',
        padding: '15px',
    },
    fine: {
     fontSize:'12px'
    },
    headerContainer:{
        flexDirection: 'row',
        justifyContent:'space-between',
        alignItems: 'center',
        padding: 10,
        borderBottomColor: 'lightgray',
        borderBottomWidth: 1,
        backgroundColor:'#fafafafa'
    }
})
export default Gate
import firebase from "firebase/app";
import 'firebase/firestore';
require('firebase/auth');
require('firebase/firebase-storage')

    const firebaseConfig = {
        apiKey: "AIzaSyDcF-zavztwSrnxh_JFfl2k8IrvEAAXn_U",
        authDomain: "fitvaultt.firebaseapp.com",
        projectId: "fitvaultt",
        storageBucket: "fitvaultt.appspot.com",
        messagingSenderId: "410784886746",
        appId: "1:410784886746:web:b8c8464c2d8f389d80dae3",
        measurementId: "G-14FSHTZVJ6"
      };

!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app()


export default firebase;
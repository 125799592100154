import React from 'react'
import { Platform } from 'react-native'
import { Image } from 'react-native'
import { StyleSheet} from 'react-native'
import { SafeAreaView } from 'react-native'
import headerimage from '../../assets/logo.png'


const Header = ( {navigation} ) => {
  return (
    <SafeAreaView style={styles.container}>

      <Image  style={{width: 140, alignSelf: 'center',
            height: 60,}} source={headerimage}/>
   
      
      </SafeAreaView>
  )
}
const styles = StyleSheet.create({
    container: {
      ...Platform.select({
        ios: {
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottomColor: 'lightgray',
          borderBottomWidth: 1,
        },
        android: {
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottomColor: 'lightgray',
          borderBottomWidth: 1,
        },
        default: {
       position:'sticky',    //Here is the trick
        top: 0, //Here is the trick
        width:'100%',
        justifyContent:'center',
        borderBottomColor: 'lightgray',
        borderBottomWidth: 1,
        backgroundColor:'white',
        zIndex: 2
        },
      }),
       
   
          
    },
    logo: {
      ...Platform.select({
        ios: {
          resizeMode: 'contain',
        },
        android: {
          resizeMode: 'contain',
        },
        default: {
          resizeMode:'cover',
           width: 140,
          height: 60,
         
         
          
        },
      }),
    
    
        
    },
})
export default Header
import { View, Text, Pressable } from 'react-native'
import React from 'react'
import { StyleSheet } from 'react-native'
import { Image } from 'react-native'
import LoginForm from '../component/home/loginScreen/LoginForm'

const LoginScreen = ( {navigation} ) => {
  return (
    <View style={styles.container}>
        <View style={styles.logo}>
            <Image style=
            {{ width:198,
                height:80}} source={require('../assets/logog.jpg')}/>
        </View>
        <LoginForm navigation={navigation}/>
    </View>
  )
}
const styles = StyleSheet.create({
    container: {
        backgroundColor:'white',
        paddingBottom:1000,
    },
    logo: {
        alignItems:"center",
        paddingTop:100,
        paddingBottom:50,
       
    }
   
})
export default LoginScreen
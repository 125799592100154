import { View, Text, Image, Alert } from 'react-native'
import React, { useEffect, useState } from 'react'
import { StyleSheet, TouchableOpacity, TextInput, ScrollView, Button } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons';
import firebase from '../firebase'
import * as Yup from 'yup'
import { Formik } from 'formik';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as ImagePicker from 'expo-image-picker';
import { useRoute } from '@react-navigation/native';


const uploadPostSchema = Yup.object().shape({
  workoutName: Yup.string().max(50,'Workout name has reached character limit')
})
const EditWorkoutScreen = ({navigation}) => {
  const email = useRoute().params.email
  const name= useRoute().params.name
  const workoutId= useRoute().params.workoutId
  const workoutNames= useRoute().params.workoutNames
 console.log(workoutNames)

  const uploadPostToFirebase = (workname) => {
  
    firebase.firestore()
        .collection('users')
        .doc(email).collection('posts').doc(workoutId).update({
          workoutNames: workoutNames.concat(workname)
        })
       
    firebase.firestore()
        .collection('users')
        .doc(email).collection('posts').doc(workoutId).collection('workoutNames').doc(workname).collection('exercises').doc()
        .set({})
        .then(() => navigation.goBack()
        )
  }

    return (
    
        <SafeAreaView>
           
            <Header navigation={navigation}/> 
            <ScrollView>
            <Formik
    initialValues={{workname:''}}
    onSubmit={(values) => {

      uploadPostToFirebase(values.workname)
    }}
    validationSchema={uploadPostSchema}
    validateOnMount={true}>
 {({ handleBlur, handleChange, handleSubmit, values, errors, isValid, push, remove}) => 
        <>
        <View style={{borderWidth:1, borderColor:'lightgray', margin:10, borderRadius:10, marginTop: 20}}>

          <TextInput 
                        style={{fontSize:20, padding:10, borderRadius:30}}
                         placeholderTextColor= 'black'
                         placeholder={`Workout Name`}
                         value={values.workname}
                        handleBlur={() => handleBlur('workname')}
                    onChangeText={handleChange('workname')}></TextInput>
                    </View>
                     <TouchableOpacity style={styles.button} onPress= {handleSubmit} title="Post" disabled={!isValid}>
                      <Text style={{color:'white', fontSize:15, alignSelf:'center'}}>Post</Text>
                      </TouchableOpacity>
        </>
}

    </Formik>
       
        
        
            <Text style={{padding:300}}></Text></ScrollView>
        </SafeAreaView>
      )
    }
    const Header = ( {navigation}) => (
      <View style={styles.headerContainer}>
              <TouchableOpacity>
                  <Ionicons name="chevron-back-outline" size={30} color='black' onPress={()=> navigation.goBack()}></Ionicons>
              </TouchableOpacity>
    
              
              <Text style={styles.headerText}>Edit Profile</Text>
              <Text/>
      </View>
    )
    
    const styles = StyleSheet.create({
      container: {
          marginHorizontal: 10,
          
      },
      headerContainer: {
          flexDirection: 'row',
          justifyContent:'space-between',
          alignItems: 'center',
          padding: 10,
          borderBottomColor: 'lightgray',
          borderBottomWidth: 1,
      },
      headerText:{
          fontWeight: 'bold',
         marginRight: 25,
         
    
      },
      button: {
        textAlign: 'center',
        backgroundColor: 'orange',
        padding: 15,
        borderRadius:30,
        fontSize:20,
        margin:25,
        marginTop:10
      }
    })
    

export default EditWorkoutScreen

import React from 'react'
import { SafeAreaView } from 'react-native'
import { StyleSheet } from 'react-native'
import Header from '../component/home/Header'
import { ScrollView } from 'react-native'

import BottomTabs from '../component/home/BottomTabs'
import SearchScreenComponent from '../component/home/SearchScreenComponent'


const SearchScreen = ({navigation}) => {
  return (
    <SafeAreaView style={styles.container} >
          
<Header navigation={navigation}/>


  <SearchScreenComponent navigation={navigation}/>




<BottomTabs navigation={navigation}/>
</SafeAreaView>
  )
}
const styles= StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
    },
    screencontainer: {
        backgroundColor: "white",
        flex: 1,
    },
})
export default SearchScreen

import { View, Text, StyleSheet,TouchableOpacity,TextInput, Image, ScrollView, Alert, Platform } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import Ionicons from '@expo/vector-icons/Ionicons';
import firebase from 'firebase';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { Divider } from 'react-native-elements';

const alertPolyfill = (title, description, options, extra) => {
  const result = window.confirm([title, description].filter(Boolean).join('\n'))

  if (result) {
      const confirmOption = options.find(({ style }) => style !== 'cancel')
      confirmOption && confirmOption.onPress()
  } else {
      const cancelOption = options.find(({ style }) => style === 'cancel')
      cancelOption && cancelOption.onPress()
  }
}

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

const RecieveScreen = ({navigation}) => {
    const [num,setNum]= useState(null)
    const [much, setMuch]= useState(null)
    const [pay, setPay]= useState(null)
    useEffect(() => {

        firebase.firestore().collection('users').doc(firebase.auth().currentUser.email).onSnapshot(doc=>{
            const much = doc.data().cost
            setMuch(much)
            console.log(much, 'this is the cost for user sub')
          } )

        firebase.firestore()
        .collection('users')
        .doc(firebase.auth().currentUser.email).onSnapshot((doc)=>{
          const earnings= doc.data().earnings
          const pay = earnings*4.99*0.8
          setPay(pay.toFixed(2))
        })

    //     .collection('Followers').get().then((querySnapshot) => {
    //         const followers = querySnapshot.docs.map((doc) => {
    //           return (doc.id)
    //         })
    //         const num = followers.length
    //         setNum(num)
    //       console.log(num,'this is the amount of followers the user has')
    //     }).then(()=>{

    //     const sum = num*much*0.8*4.99
    //     const newsum= sum.toFixed(2)
    //     console.log(newsum, 'fuck you this is the sum')
    //     setPay(newsum)
        
    // })
         
            
    },[pay])

    const RegisterFormSchema = Yup.object().shape({
        name: Yup.string().required().min(3,'A name is required'),
        address: Yup.string().required().min(3,'A name is required'),
        bank: Yup.string().required().min(3,'A name is required'),
        anum: Yup.string().required().min(3,'A name is required'),
        rnum: Yup.string().required().min(9,'A name is required'),
        checkistrue: Yup.string().required().min(3,'A name is required'),
        sig: Yup.string().required().min(3,'A name is required'),
        date: Yup.string().required().min(3,'A name is required'),
    })

    const onGo = async (name, address, bank, anum, rnum, checkistrue, sig, date) => {
      console.log(name, address, bank, anum, rnum, checkistrue, sig, date)
        try{
            
            
            firebase.firestore().collection('payees').doc(firebase.auth().currentUser.email).collection('directDeposit').doc('bankinfo').set({
                name:name,
                address:address,
                bank:bank,
                anum:anum,
                rnum:rnum,
                checkistrue:checkistrue,
                sig:sig,
                date:date,
            })
          
               
            navigation.goBack()
         
          
             }catch(error){
        alert(error.message)
      
        
    }
    // alert(
    //     "Banking Information Successfully Updated",
    
    //     [

    //       { text: "OK", onPress: () =>  navigation.goBack() }
    //     ]
    //   )
  
    
}

  return (
  
    <SafeAreaView>
        <Header navigation={navigation}/>
        <ScrollView>
            <View style={{flexDirection:'row', padding:15, }}>
            <Text style={{fontSize:18, fontWeight:'bold'}}>Your Estimated Monthly Earnings</Text> 
            <Ionicons style={{paddingHorizontal:10}}name="help-circle-outline" size={20} color='gray' onPress={()=> {
                   alert(
                    "Estimated Earnings",
                    "This figure is not exact because of refunds, changes in subscription cost, etc.",
                    [

                      { text: "OK", onPress: () => console.log("OK Pressed") }
                    ]
                  );
            }}></Ionicons>
            </View>
           <Text style={{textAlign:'center', fontSize:35, fontWeight:'bold', color:'green', padding:5}}>${pay}</Text>
           <Divider style={{marginTop:20}}/>
           <Text style={{fontSize:18, fontWeight:'bold', padding:15, paddingBottom:10}}>Your Payment Information</Text>
           {pay>=10 ? (

<View>
          
           <Text style={{color:'red', paddingLeft:20, paddingBottom:5, paddingRight:20}}>Do not enter if already completed, unless you are updating information. Payments are issued the 1st of each month.</Text>
           <Formik
           initialValues={{name: '', address:'', bank:'', anum:'', rnum:'', checkistrue:'', sig:'',date:''}}
           onSubmit={(values) => {
               onGo(values.name, values.address, values.bank, values.anum, values.rnum, values.checkistrue, values.sig, values.date)
               
           }}
           validationSchema={RegisterFormSchema}
           validateOnMount={true}>
            {({ handleBlur, handleChange, handleSubmit, values, isValid, errors }) => 
        <>
        <Text style={{fontSize:18, padding:5, paddingLeft:15}}>Full name:</Text>
        <View style={[styles.inputs,
    {borderColor: values.name.length < 1 || values.name.length>=3 ? 'black' : "red"}]}>
           <TextInput style={{padding: 15}}placeholder='' placeholderTextColor={'black'} onChangeText={handleChange('name')} onBlur={handleBlur('name')} value={values.name}/> 
        </View>
        
        <Text style={{fontSize:18, padding:5, paddingLeft:15}}>Address:</Text>
        <View style={[styles.inputs,
    {borderColor: values.address.length < 1 || values.address.length>=3 ? 'black' : "red"}]}>
           <TextInput style={{padding: 15}}placeholder='' placeholderTextColor={'black'} onChangeText={handleChange('address')} onBlur={handleBlur('address')} value={values.address}/> 
        </View>
        <Text style={{fontSize:18, padding:5, paddingLeft:15}}>Name of Bank:</Text>
        <View style={[styles.inputs,
    {borderColor: values.bank.length < 1 || values.bank.length>=3 ? 'black' : "red"}]}>
           <TextInput style={{padding: 15}}placeholder='' placeholderTextColor={'black'} onChangeText={handleChange('bank')} onBlur={handleBlur('bank')} value={values.bank}/> 
        </View>
        
        <Image
        style={{
            marginVertical:10,
            borderWidth:2,
            borderColor:'orange',
            alignSelf:'center',
            height:130,
            width:250,
        }}
        source={{
          uri: 'https://firebasestorage.googleapis.com/v0/b/fitvaultt.appspot.com/o/Bank_check_routing_account_numbers_tcm108-35067.gif?alt=media&token=425091cb-412a-4773-91e4-5384a3bdfc58',
        }}
      />
        <Text style={{fontSize:18, padding:5, paddingLeft:15}}>Account Number:</Text>
        <View style={[styles.inputs,
    {borderColor: values.anum.length < 1 || values.anum.length>=6 ? 'black' : "red"}]}>
           <TextInput style={{padding: 15}}placeholder='' placeholderTextColor={'black'} onChangeText={handleChange('anum')} onBlur={handleBlur('anum')} value={values.anum}/> 
        </View>
        <Text style={{fontSize:18, padding:5, paddingLeft:15}}>9-digit routing number</Text>
        <View style={[styles.inputs,
    {borderColor: values.rnum.length < 1 || values.rnum.length>=8 ? 'black' : "red"}]}>
           <TextInput style={{padding: 15}}placeholder='' placeholderTextColor={'black'} onChangeText={handleChange('rnum')} onBlur={handleBlur('rnum')} value={values.rnum}/> 
        </View>
        <Text style={{fontSize:18, padding:5, paddingLeft:15}}>Savings Account or Checking Account?</Text>
        <View style={[styles.inputs,
    {borderColor: values.checkistrue.length < 1 || values.checkistrue.length>=3 ? 'black' : "red"}]}>
           <TextInput style={{padding: 15}}placeholder='' placeholderTextColor={'black'} onChangeText={handleChange('checkistrue')} onBlur={handleBlur('checkistrue')} value={values.checkistrue}/> 
        </View>
        <Text style={{fontSize:12, padding:5, paddingLeft:15}}>FitVault is hereby authorized to directly deposit my pay to the account listed above. This authorization will remain in effect until I modify or cancel it.</Text>
        <Text style={{fontSize:18, padding:5, paddingLeft:15}}>Signature:</Text>
        <View style={[styles.inputs,
    {borderColor: values.sig.length < 1 || values.sig.length>=3 ? 'black' : "red"}]}>
           <TextInput style={{padding: 15}}placeholder='' placeholderTextColor={'black'} onChangeText={handleChange('sig')} onBlur={handleBlur('sig')} value={values.sig}/> 
        </View>
        <Text style={{fontSize:18, padding:5, paddingLeft:15}}>Date:</Text>
        <View style={[styles.inputs,
    {borderColor: values.date.length < 1 || values.date.length>=8 ? 'black' : "red"}]}>
           <TextInput style={{padding: 15}}placeholder='' placeholderTextColor={'black'} onChangeText={handleChange('date')} onBlur={handleBlur('date')} value={values.date}/> 
        </View>
        <TouchableOpacity onPress={handleSubmit} disabled={!isValid}>
        <View style={styles.button}>
            <Text style={{color:'white', padding:10, fontSize:20}}>Submit</Text>
        </View>
        </TouchableOpacity> 
        </>}
        
           </Formik>
          </View>
                      ):(
<Text style={{textAlign:'center', padding:10}}>You must make over $10 to claim direct deposit.</Text>
                        )}
           <Divider style={{paddingVertical:500}}/>
        </ScrollView>
    </SafeAreaView>
  )
}
const Header = ( {navigation}) => (
    <View style={styles.headerContainer}>
            <TouchableOpacity>
                <Ionicons name="chevron-back-outline" size={30} color='black' onPress={()=> navigation.goBack()}></Ionicons>
            </TouchableOpacity>
  
            
            <Text style={styles.headerText}>Recieve Payments</Text>
            <Text/>
    </View>
  )
  
  const styles = StyleSheet.create({
    container: {
        marginHorizontal: 10,
        
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent:'space-between',
        alignItems: 'center',
        padding: 10,
        borderBottomColor: 'lightgray',
        borderBottomWidth: 1,
    },
    headerText:{
        fontWeight: 'bold',
       marginRight: 25,
       
  
    },
    inputs: {
    
        marginHorizontal: 10,
        borderColor:"black",
        borderWidth: 1,
        marginBottom:20,
        borderRadius:10
    },
    button:{
        backgroundColor:'orange',
        alignItems:'center',
        borderRadius:30,
        marginHorizontal:40,
        marginTop:20

    }
  })
  
export default RecieveScreen
import React, { useEffect, useState } from 'react'
import { Text } from 'react-native';
import { View } from 'react-native';
import { StyleSheet, TouchableOpacity } from 'react-native';
import firebase from '../firebase';
import Ionicons from '@expo/vector-icons/Ionicons';

function Change({navigation}) {

    const email= firebase.auth().currentUser.email
    const [cplan, setCplan]= useState(null)
    const [empty, setEmpty] = useState(null)

    useEffect(()=>{
        firebase.firestore().collection('users').doc(email).collection('userFollowing').get().then(list =>{
       
            
            if(list.docs.length >0){
                setEmpty(false)
            }else{
                setEmpty(true)
            }
        
        })
        
    }, [empty])
  return (
    <View>
        <View style={styles.headerContainer}>
            <TouchableOpacity>
                <Ionicons name="chevron-back-outline" size={30} color='black' onPress={()=> navigation.goBack()}></Ionicons>
            </TouchableOpacity>
  
            
            <Text style={styles.headerText}></Text>
            <Text/>
    </View>
    <View style={styles.body}>
<Text style={{  marginLeft:10, marginRight:10 , fontSize:22, fontWeight:'bold', fontFamily:"sans-serif", color:'orange', textAlign:'left', marginLeft:20}}>Change Plan</Text>
<View>
   
        {/* <View  className='tr'>
                <View className='t'>
                    <Text  style={{fontFamily:'sans-serif', padding:20,}}>Hello, {email}</Text><br/><br/>
                    <Text style={{fontFamily:'sans-serif', padding:20,}}>You do not have a current plan.</Text>
                </View>
                <View className='t'>
                    <Text style={{fontFamily:'sans-serif', padding:20,}}>If you are sure you have a plan and it is not showing up contact our support email mattduarte56@gmail.com.</Text>
                </View>
                <View onPress={ ()=>{navigate('/pricing', {state:{user:email}})}}>
                     <button className='button'>Click here to start a subscription.</button>
                </View>
        
        

        </View> */}
  
        
            <View>
                {/* <View>
                    <Text style={{fontFamily:'sans-serif', padding:10, marginLeft:30 , paddingBottom:0}}>Current Plan: {cplan} Plan</Text>
                   
                </View> */}
                <View style={{padding:30}}>
                <Text style={{fontFamily:'sans-serif', fontSize:'15px'}}>Thank you for being a loyal FitVault customer. I made this service because I wanted to create an amazing community of people that are passionate about fitness. I have always been curious about what my favorite athletes and influencers are doing in the gym. <br></br><br></br>With FitVault, people can affordably have accesss to advanced training programs and creators can get paid very well for providing them. I hope that you enjoy our service. If you have any suggestions contact me at the support email.</Text>
                   
                   <Text style={{fontFamily:'sans-serif', textAlign:'right'}} >-Matthew Duarte, Creator of FitVault</Text>
                    </View>
                    <TouchableOpacity style={{
                            backgroundColor: 'orange' ,/* Green */
                            color:'white',
                            borderRadius: '15px',
                            padding: '10px 32px',
                            textAlign: 'center',
                            cursor: 'pointer',
                          margin:'15px'
                    }} onPress={async () =>{
                        if(empty===true){
                             console.log('clickedfff')
          const functionRef =  firebase
          .app()
          .functions('us-central1')
          .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
        const { data } =  await functionRef({
          returnUrl: window.location.origin,
          locale: "auto", // Optional, defaults to "auto
   
        });

        window.location.assign(data.url);
                        }else{
                            console.log(empty)
                            alert('You cannot change your plan while following users, please unfollow all accounts to make changes to your account')
                        }
         
        }}>
                   <Text style={{color:'white', fontSize:'15px', padding:'15px'}}>Manage Subscription</Text>
                    </TouchableOpacity>
                
                

            </View>
        


    
    </View>
    </View>
    </View>
  )
}
const styles= StyleSheet.create({
    body: {
        paddingTop:'20px',
backgroundColor:'#fafafafa'
    },
    tr: {
        margin: '15px',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '0 5px 15px #00000029',
        borderRadius: '15px',
        cursor: 'pointer',
        display: 'block',
    },
    cost: {   
        
    },
    fine: {
     
    },
    headerContainer:{
        flexDirection: 'row',
        justifyContent:'space-between',
        alignItems: 'center',
        padding: 10,
        borderBottomColor: 'lightgray',
        borderBottomWidth: 1,
        backgroundColor:'#fafafafa'
    }
})
export default Change
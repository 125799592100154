import { View, Text } from 'react-native'
import React from 'react'
import { StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons';
import FormikPostUploader from './FormikPostUploader';

const AddNewPost = ( {navigation} ) => {
  return (
    <View style={styles.container}>
        <Header navigation={navigation}/>
        <FormikPostUploader navigation={navigation}/>
    </View>
  )
}
const Header = ( {navigation}) => (
    <View style={styles.headerContainer}>
            <TouchableOpacity>
                <Ionicons name="chevron-back-outline" size={30} color='black' onPress={()=> navigation.goBack()}></Ionicons>
            </TouchableOpacity>

            
            <Text style={styles.headerText}>New Workout Plan</Text>
            <Text/>
    </View>
)

const styles = StyleSheet.create({
    container: {
        marginHorizontal: 10,
        
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent:'space-between',
        alignItems: 'center',
        padding: 10,
        borderBottomColor: 'lightgray',
        borderBottomWidth: 1,
    },
    headerText:{
        fontWeight: 'bold',
       marginRight: 25,
       

    }
})

export default AddNewPost
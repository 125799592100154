import { Text, Alert, SafeAreaView, View, StyleSheet, Image, TouchableOpacity, TextInput, ScrollView, Platform } from 'react-native'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Ionicons from '@expo/vector-icons/Ionicons';
import React from 'react'
import firebase from '../firebase'
import Validator from 'email-validator'

const alertPolyfill = (title, description, options, extra) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'))
 
    if (result) {
        const confirmOption = options.find(({ style }) => style !== 'cancel')
        confirmOption && confirmOption.onPress()
    } else {
        const cancelOption = options.find(({ style }) => style === 'cancel')
        cancelOption && cancelOption.onPress()
    }
 }
 
 const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('An email is required'),
          
      })
const Forgot = ({navigation}) => {
    const sendIt = (email)=>{
            firebase.auth().sendPasswordResetEmail(email).then(()=>
            navigation.goBack()).catch(function(error){
                console.log(error)
                alert("Email Sent")
            })
            alert("Email Sent")
    }
    return (
        <SafeAreaView style={styles.container}>
           
            <Header navigation={navigation}/> 
            <ScrollView>
            <View style={styles.logo}>
                <Image style={{width:198, height:80}} source={require('../assets/logog.jpg')}/> 
                </View>
                <Formik 
                initialValues={{email:''}}
                onSubmit={(values) => {
                sendIt(values.email)
                   
                    }}
                validationSchema={validationSchema}
                validateOnMount={true}>

                
                {({handleChange, handleBlur, handleSubmit, values, isValid}) => <>
                <View  style={[styles.inputs,
    {borderColor: values.email.length < 1 || Validator.validate(values.email)  ? 'black' : "red"}]}>
            <TextInput style={{padding: 15}}placeholder='Email' placeholderTextColor={'black'} onChangeText={handleChange('email')} onBlur={handleBlur('email')} value={values.email}/>
            
            </View>   
            <TouchableOpacity onPress={handleSubmit} disabled={!isValid}>
                <View style={styles.button}>
            <Text style={{color:'white',  padding:10, fontSize:20}}>Send Recovery Email</Text>
            
        </View>
            </TouchableOpacity>
            <Text style={{color:'gray', paddingVertical:30, textAlign:'center'}}>Email might be in spam folder.</Text>
        <View style={{marginBottom:500,}}></View>
                </>}
             
              </Formik>
           
</ScrollView>
        </SafeAreaView>
      )
    }
    const Header = ( {navigation}) => (
        <View style={styles.headerContainer}>
                <TouchableOpacity>
                    <Ionicons name="chevron-back-outline" size={30} color='black' onPress={()=> navigation.goBack()}></Ionicons>
                </TouchableOpacity>
      
                
               
                <Text/>
        </View>
      )
    const styles = StyleSheet.create({
        container: {
            backgroundColor:'white',
            paddingBottom:1000,
        },
        headerContainer: {
            flexDirection: 'row',
            justifyContent:'space-between',
            alignItems: 'center',
            padding: 10,
            borderBottomColor: 'lightgray',
            borderBottomWidth: 1,
        },
        logo: {
            alignItems:"center",
            paddingTop:100,
            paddingBottom:50,
        },
        inputs:{
            backgroundColor:"#fafafafa",
            marginHorizontal: 30,
            borderColor:"black",
            borderWidth: 1,
            marginBottom:20,
            borderRadius:30
        },
        text:{
            color:"gray",
        },
        button:{
            backgroundColor:'orange',
            alignItems:'center',
            borderRadius:30,
            marginHorizontal:40,
            marginTop:20
    
        }
       
    })
export default Forgot
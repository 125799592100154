import { View, Text , Alert, Platform} from 'react-native'
import React, { useEffect, useState,  } from 'react'
import { StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons';
import firebase from '../../firebase'
import { ScrollView } from 'react-native';

const alertPolyfill = (title, description, options, extra) => {
  const result = window.confirm([title, description].filter(Boolean).join('\n'))

  if (result) {
      const confirmOption = options.find(({ style }) => style !== 'cancel')
      confirmOption && confirmOption.onPress()
  } else {
      const cancelOption = options.find(({ style }) => style === 'cancel')
      cancelOption && cancelOption.onPress()
  }
}

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

const BigPost = ({navigation,workoutId, email}) => {
    const [plan, setPlan] = useState([])
    const [workoutNames, setWorkoutNames]= useState([])
    const [show, setShow]= useState(false)
    console.log(workoutId, email, 'post')
   useEffect(()=>{
    firebase.firestore().collection('users').doc(email).collection('posts').doc(workoutId).onSnapshot((doc) => {
        if(doc.exists){
            const plan =[]
            plan.push(doc.data().plan)
            setPlan(plan)
            const workoutNames = doc.data().workoutNames
       
            setWorkoutNames(workoutNames)
        }else{

         }
      
    
//     firebase.firestore()
//     .collection("users")
//     .doc(firebase.auth().currentUser.email)
//     .collection("userFollowing")
//     .doc(email)
//     .onSnapshot((doc) => {
//         if (doc.exists) {

//             setShow(true)
//             }
//         if (firebase.auth().currentUser.email === email){
//             setShow(true)
            
//         }else{
//                 setShow(false)
//             }
//   }); 
}) 
  console.log(show)
   }, [])

   const LongPress = ({navigation, workoutId, email, })=>{
    console.log(email, 'this is email',firebase.auth().currentUser.email, 'this is cureloglongpre' )
        firebase.firestore().collection('users').doc(email).collection('posts').doc(workoutId).delete().then(() => {
            navigation.goBack(2)

            console.log("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
        

}


   
    return (
        <View style={styles.container}>
            <Header navigation={navigation}/>
            <ScrollView style={{paddingBottom: 500}}>

            <View style={{borderBottomColor:"lightgray", borderBottomWidth:1, backgroundColor:'orange'}}>
        <TouchableOpacity onLongPress={()=>{
             if(firebase.auth().currentUser.email===email){
                alert(
                    "Are you sure you want to delete workout plan?",
                    "This will delete your entire plan.",
                    [
                      {
                        text: "Cancel",
                        onPress: () => console.log("Cancel Pressed"),
                        style: "cancel"
                      },
                      { text: "Delete Plan", onPress: () => {LongPress({email:email, navigation:navigation,workoutId:workoutId})} }
                    ]
                  );
                
            }else{
                alert(
                    "Are you sure you want to report this workout plan?",
                    "This action cannot be undone.",
                    [
                      {
                        text: "Cancel",
                        onPress: () => console.log("Cancel Pressed"),
                        style: "cancel"
                      },
                      { text: "Report Plan", onPress: () => console.log("User Reported"),}
                    ]
                  );
            }
                  
        }}>
             <Text style={styles.header}>{plan}</Text>
        </TouchableOpacity>
       
        {/* .map((post, index)=>(
                <Post post= {post} key= {index} navigation={navigation}/>    
            ))} */}
     </View>
     {workoutNames.map((name, index) => {
        return (
    
                    <TouchableOpacity  key = {index} style={styles.click} onLongPress={()=>{
                        alert(
                            "Delete Workout",
                            "Are you sure you want to delete this workout?",
                            [
                              {
                                text: "Cancel",
                                onPress: () => console.log("Cancel Pressed"),
                                style: "cancel"
                              },
                              { text: "Delete", onPress: () =>{
                                firebase.firestore().collection('users').doc(email).collection('posts').doc(workoutId).update({
                                    workoutNames:firebase.firestore.FieldValue.arrayRemove(name)
                                }).then(()=>{
                                    firebase.firestore().collection('users').doc(email).collection('posts').doc(workoutId).collection('workoutNames').doc(name).delete().then(() => {
                                        console.log(name,'this is the name')
                                        console.log("Document successfully deleted!");
                                    }).catch((error) => {
                                        console.error("Error removing document: ", error);
                                    });
                                    console.log('potter')
                                })
                              }}
                            ]
                          );
                      
                        }}
                        onPress={() => navigation.navigate('WorkoutScreen',{workoutId:workoutId ,email:email,name: name})}>
                    <Text style={styles.title}>{name}</Text>
                </TouchableOpacity>)
 })}
 {email !== firebase.auth().currentUser.email  ? (
    <Text/>
 ):
<View style={{paddingVertical:150}}>
                    <TouchableOpacity style={{margin:15, borderRadius:30,alignItems:'center', backgroundColor: 'orange'}}
                    onPress={() => navigation.navigate('EditWorkoutScreen', {email:email, workoutId:workoutId, workoutNames:workoutNames,})}>
                      
                      <Text style={{color:'white' ,padding:10 ,fontSize:15}}>Add Workout</Text>
                      

                      
                    </TouchableOpacity>

                  </View>
 }
   
            </ScrollView>
        </View>
      )
    }
    const Header = ( {navigation, workoutId}) => (
        <View style={styles.headerContainer}>
                <TouchableOpacity>
                    <Ionicons name="chevron-back-outline" size={30} color='black' onPress={()=> navigation.goBack()}></Ionicons>
                </TouchableOpacity>
            
                
                <Text style={styles.headerText}></Text>
                
        </View>
    )
 
    
    const styles = StyleSheet.create({
        container: {
            
            
        },
        headerContainer: {
            flexDirection: 'row',
            justifyContent:'space-between',
            alignItems: 'center',
            padding: 10,
            borderBottomColor: 'lightgray',
            borderBottomWidth: 1,
        },
        headerText:{
            fontWeight: 'bold',
           marginRight: 30,
           
    
        },
        click:{
            borderBottomColor:"lightgray",
            borderBottomWidth: 1,
            
        },
        title:{
            fontSize:15,
            padding:15,
            paddingLeft:40
        },
        header:{
            fontSize:18,
            padding:20,
            color: 'white',
            textAlign:'center',
            fontWeight: 'bold'

            
        }
    })

export default BigPost
import { View, Text } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Touchable, TouchableOpacity } from 'react-native'
import { Divider } from 'react-native-elements'
import { StyleSheet } from 'react-native'
import { Image } from 'react-native'
import firebase from '../../firebase'

// const LogOut = ({navigation}) => { navigation.navigate('BigPostScreen')

// }
const Post = ({  post, navigation, shown }) => {

  return (
    <View style={{backgroundColor: "#fafafafa"}}>
    
   <TouchableOpacity onPress={() => navigation.navigate('BigPostScreen', {workoutId: post.workoutId, email: post.email})} style={{ marginBottom: 30 }}>
       <Divider width={1} orientation= 'vertical'/>
       <PostHeader post = {post}/>
   </TouchableOpacity>
   </View>
  )
}
const PostHeader = ({ post }) => (
    <View style={{backgroundColor: "white"}} >
       <View style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 5,
        alignItems: 'center'}}>
            
        <View style={{flexDirection:'row', alignItems:'center'}}>
            <Image source={{uri: post.profile_picture}} style={styles.story}/>
            <Text style={{color: "black", marginLeft: 5,}}>
                {post.user}
            </Text>
            
            
            
            
        </View>
      
    </View>
      <View style={{padding: 15, paddingBottom: 35, borderTopWidth:1, borderTopColor:"lightgray", borderBottomWidth:1, borderBottomColor:"lightgray"}}>
      <Text style={{textAlign:"center", paddingBottom:7, fontWeight:"bold"}}>{post.plan}</Text>
      <Text>{post.bio}</Text>
     
  </View>
  <View style={{padding: 12, borderBottomWidth:1, borderBottomColor:"lightgray"}}>
        <Text  style={{textAlign:"center"}}>Click to see plan</Text>
    </View>
  </View>
)

const styles= StyleSheet.create({
    story: {
        width: 35,
        height: 35,
        borderRadius: 50,
        marginLeft: 6,
        borderWidth: 3,
        borderColor: "#ff8501",
    }
})
export default Post
import { View, Text, Alert, Platform } from 'react-native'
import React, { useEffect, useState } from 'react'
import { StyleSheet, TouchableOpacity, ScrollView, Button } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons';
import firebase from '../firebase'
import { SafeAreaView } from 'react-native-safe-area-context';
import { Divider } from 'react-native-elements';
const alertPolyfill = (title, description, options, extra) => {
  const result = window.confirm([title, description].filter(Boolean).join('\n'))

  if (result) {
      const confirmOption = options.find(({ style }) => style !== 'cancel')
      confirmOption && confirmOption.onPress()
  } else {
      const cancelOption = options.find(({ style }) => style === 'cancel')
      cancelOption && cancelOption.onPress()
  }
}

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

const ChangeCostScreen = ({navigation}) => {
    const [cost, setCost] = useState('')
    const [much, setMuch] = useState('')
   
     useEffect(()=> {
         firebase.firestore().collection('users').doc(firebase.auth().currentUser.email).onSnapshot(doc=>{
           const much = doc.data().cost
           setMuch(much)
         } )
     },[])
  return (
    <SafeAreaView>
       
        <Header navigation={navigation}/> 
    <ScrollView>
   
    {/* <Text style={{textAlign:'center', fontSize:18, padding:18 ,paddingBottom:0}}>Change Subscription Cost</Text> */}
    <Text style={{textAlign:'center', fontSize:12, padding:12}}>Current Cost: {much}</Text>
    <Divider style={{padding:0}} color='lightgray'/>
    <View style={{flexDirection:'row'}}>
      <View style={{width:'50%'}}>
        <TouchableOpacity style={{backgroundColor:'orange', borderRadius:30, margin : 20, marginRight:5}} onPress={()=>{
          setCost('Free')
        }}>
          <Text style={{textAlign:"center", padding:15, color:'white', fontSize:15}}>Free</Text>
        </TouchableOpacity>
        <TouchableOpacity style={{backgroundColor:'orange', borderRadius:30, margin : 20, marginRight:5}}  onPress={()=>{
          setCost('2')
        }}>
          <Text style={{textAlign:"center", padding:15, color:'white', fontSize:15}}>2 Credits</Text>
        </TouchableOpacity>
        <TouchableOpacity style={{backgroundColor:'orange', borderRadius:30, margin : 20, marginRight:5}}  onPress={()=>{
          setCost('4')
        }}>
          <Text style={{textAlign:"center", padding:15, color:'white', fontSize:15}}>4 Credits</Text>
        </TouchableOpacity>
      </View>
      <View style={{width:'50%'}}>
      <TouchableOpacity style={{backgroundColor:'orange', borderRadius:30, margin : 20, marginLeft:5}}  onPress={()=>{
          setCost('1')
        }}>
          <Text style={{textAlign:"center", padding:15, color:'white', fontSize:15}}>1 Credit</Text>
        </TouchableOpacity>
        <TouchableOpacity style={{backgroundColor:'orange', borderRadius:30, margin : 20, marginLeft:5}}  onPress={()=>{
          setCost('3')
        }}>
          <Text style={{textAlign:"center", padding:15, color:'white', fontSize:15}}>3 Credits</Text>
        </TouchableOpacity>
        <TouchableOpacity style={{backgroundColor:'orange', borderRadius:30, margin : 20, marginLeft:5}}  onPress={()=>{
          setCost('5')
        }}>
          <Text style={{textAlign:"center", padding:15, color:'white', fontSize:15}}>5 Credits</Text>
        </TouchableOpacity>
        </View>
        
    </View>
    <View style={{alignItems:'center'}}>
      <Button title='Update Price' onPress={()=>{
        if (cost===''){
          alert('Select Value', 'No price selected', [
    
            { text: 'OK', onPress: () => console.log('OK Pressed') },
          ]);
      
        }else{
          firebase.firestore()
          .collection('users')
          .doc(firebase.auth().currentUser.email)
          .update({
           
           cost:cost
             
          }).then(()=> {

            if(cost==='Free'){
                    firebase.firestore()
                .collection('users')
                .doc(firebase.auth().currentUser.email)
                .update({
           
                 isPaid: false
             
                }).then(()=> navigation.goBack())
                }else{
                     firebase.firestore()
                    .collection('users')
                    .doc(firebase.auth().currentUser.email)
                    .update({
             
                     isPaid: true
               
                    }).then(()=> navigation.goBack())
                    } 

          })
       
                
    }
      }}/>
    </View>
       
        
        
            <Text style={{padding:500}}></Text></ScrollView>
            </SafeAreaView>
  )
}
const Header = ( {navigation}) => (
    <View style={styles.headerContainer}>
            <TouchableOpacity>
                <Ionicons name="chevron-back-outline" size={30} color='black' onPress={()=> navigation.goBack()}></Ionicons>
            </TouchableOpacity>
  
            
            <Text style={styles.headerText}>Change Subscription Amount</Text>
            <Text/>
    </View>
  )
  
  const styles = StyleSheet.create({
    container: {
        marginHorizontal: 10,
        
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent:'space-between',
        alignItems: 'center',
        padding: 10,
        borderBottomColor: 'lightgray',
        borderBottomWidth: 1,
    },
    headerText:{
        fontWeight: 'bold',
       marginRight: 25,
       
  
    }
  })
export default ChangeCostScreen
import React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack';
import NewPostScreen from './screens/NewPostScreen'
import HomeScreen from './screens/HomeScreen'
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ProfileScreen from './screens/ProfileScreen';
import SettingsScreen from './screens/SettingsScreen';
import BigPostScreen from './screens/BigPostScreen';
import WorkoutScreen from './screens/WorkoutScreen';
import SearchScreen from './screens/SearchScreen';
import FollowingScreen from './screens/FollowingScreen';
import NewExerciseScreen from './screens/NewExerciseScreen';
import EditProfileScreen from './screens/EditProfileScreen';
import EditWorkoutScreen from './screens/EditWorkoutScreen'
import Forgot from './screens/Forgot';
import ChangeCostScreen from './screens/ChangeCostScreen';
import RecieveScreen from './screens/RecieveScreen';
import Gate from './screens/Gate';
import Checkout from './screens/Checkout';

const Stack= createStackNavigator();

const screenOptions={
    headerShown: false,
    animationEnabled: false ,
    animation: 'none' 
    
}
const config = {
   
  };
  
  const linking = {
    prefixes: ['https://thefitvault.com/', 'mychat://'],
     screens: {
      ProfileScreen: 'user',
    },
  };

 export const SignedInStack = () => (
    <NavigationContainer linking={linking}> 
        <Stack.Navigator 
        initialRouteName= "HomeScreen"
        screenOptions={screenOptions}>
            <Stack.Screen name='HomeScreen' component={HomeScreen}  />
            <Stack.Screen name='NewPostScreen' component={NewPostScreen}/>
            <Stack.Screen name='ProfileScreen' component={ProfileScreen} />
            <Stack.Screen name='SettingsScreen' component={SettingsScreen}/>
            <Stack.Screen name='LoginScreen' component={LoginScreen}/>
            <Stack.Screen name='BigPostScreen' component={BigPostScreen}/>
            <Stack.Screen name='WorkoutScreen' component={WorkoutScreen}/>
            <Stack.Screen name='SearchScreen' component={SearchScreen}/>
            <Stack.Screen name='FollowingScreen' component={FollowingScreen}/>
            <Stack.Screen name='NewExerciseScreen' component={NewExerciseScreen}/>
            <Stack.Screen name='EditProfileScreen' component={EditProfileScreen}/>
            <Stack.Screen name='EditWorkoutScreen' component={EditWorkoutScreen}/>
            <Stack.Screen name='ChangeCostScreen' component={ChangeCostScreen}/>
            <Stack.Screen name='RecieveScreen' component={RecieveScreen}/>
            <Stack.Screen name='Gate' component={Gate}/>
            <Stack.Screen name='Checkout' component={Checkout}/>
        </Stack.Navigator>
    </NavigationContainer>

  )

export const SignedOutStack = () => (
    <NavigationContainer> 
    <Stack.Navigator
    initialRouteName= 'LoginScreen'
    screenOptions={screenOptions}>
        <Stack.Screen name='LoginScreen' component={LoginScreen}/>
        <Stack.Screen name='RegisterScreen' component={RegisterScreen}/>
        <Stack.Screen name='Forgot' component={Forgot}/>
    </Stack.Navigator>
</NavigationContainer>
  )
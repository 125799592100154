import { View, TextInput, TouchableOpacity, Text } from 'react-native'
import React from 'react'
import { StyleSheet } from 'react-native'
import { useState } from 'react'
import { FlatList } from 'react-native'
import firebase from '../../firebase'
import { ScrollView } from 'react-native'
require('firebase/firestore')




const SearchScreenComponent = ({navigation}) => {
    const [users, setUsers] = useState([])
    const [userProfile, setUserProfile] = useState([])

    const fetchUsers = (search) => {
        firebase.firestore()
        .collection("users").where('username', '>=', search)
        .get()
        .then((snapshot) => {
            let users= snapshot.docs.map(doc => {
                const data = doc.data();
                const id = doc.id;
                return{ id, ...data }
                
        });
        
        setUsers(users)
        
    })
    }
  return (
    <ScrollView>
        <View style={{borderRadius:10, borderColor:'orange',borderWidth:1 , margin: 5}}>
    <TextInput onChangeText={(search) => fetchUsers(search)} 
    placeholder="Type Here to Search for User" style={{padding:10,}} placeholderTextColor='black' 
    />
    </View>

    <FlatList style={{padding:10}}
        numColumns={1}
        horizontal={false}
        data={users}
        renderItem={({ item }) => (
           
            <TouchableOpacity onPress={() => navigation.push('ProfileScreen',{ email : item.id})}
            >
                <Text style={{ fontSize: 17 ,padding:5}}>{item.username}</Text>
            </TouchableOpacity>
         )}
         
    />
   
</ScrollView>

)
}
const styles= StyleSheet.create({
    searchbar: {
        fontSize:15 , padding:15, borderWidth:1, margin:10, borderRadius:10, paddingLeft:10
    },
})

export default SearchScreenComponent
import { View, Text } from 'react-native'
import React from 'react'
import Ionicons from '@expo/vector-icons/Ionicons';
import { TouchableOpacity } from 'react-native';
import firebase from '../../firebase';
import { Platform } from 'react-native';
import { StyleSheet } from 'react-native';


const BottomTabs = ({navigation}) => {

  return (
    <View style={styles.container}>

    
    <View style={{flexDirection:'row', justifyContent:'space-evenly' , paddingTop: 10, paddingBottom: 10,  borderTopWidth:1, borderTopColor:"lightgray",}}>
      <TouchableOpacity>
      <Ionicons onPress={() => navigation.push('HomeScreen')}name="home-outline" size={45} color='black' ></Ionicons>
      </TouchableOpacity>

      <TouchableOpacity>
      <Ionicons name="search-outline" size={45} color='black' onPress={() => navigation.navigate('SearchScreen')}  ></Ionicons>
      </TouchableOpacity>

      <TouchableOpacity>
      <Ionicons name="barbell-outline" size={45} color='black' onPress={() => navigation.push('FollowingScreen', {email: firebase.auth().currentUser.email})}></Ionicons>
      </TouchableOpacity>

      <TouchableOpacity  onPress={() => navigation.push('ProfileScreen', {email: firebase.auth().currentUser.email})} >
      <Ionicons  name="person-outline" size={45} color='black'></Ionicons>
      </TouchableOpacity>

      <TouchableOpacity onPress={() => navigation.navigate('SettingsScreen')}>
      <Ionicons name="settings-outline" size={45} color='black' ></Ionicons>
      </TouchableOpacity>

    </View>
    </View>
  )
}



const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      ios: {
   
      },
      android: {
    
      },
      default: {
       
        position: 'fixed', //Here is the trick
        bottom: 0, //Here is the trick
        backgroundColor:'white',
        justifyContent: 'space-evenly'
        ,width:'100%',
      },
    }),
            
  },
})
export default BottomTabs


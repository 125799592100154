import { View, Text, Alert, TextInput, Platform } from 'react-native'
import React from 'react'
import { StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native'
import { Formik } from 'formik'
import * as Yup from 'yup'
import firebase from '../../../firebase'
import Validator from 'email-validator'

const alertPolyfill = (title, description, options, extra) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'))
 
    if (result) {
        const confirmOption = options.find(({ style }) => style !== 'cancel')
        confirmOption && confirmOption.onPress()
    } else {
        const cancelOption = options.find(({ style }) => style === 'cancel')
        cancelOption && cancelOption.onPress()
    }
 }
 
 const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

const LoginForm = ( {navigation} ) => {
    const LoginFormSchema = Yup.object().shape({
        email: Yup.string().email().required('An email is required'),
        password: Yup.string().required().min(8, 'Password must be 8 characters')
    })

const onLogin = async (email, password) => {
    try{
        await firebase.auth().signInWithEmailAndPassword(email, password)
        console.log('login worked penis', email, password)
        
    }catch(error){
        alert("User not found, try again or click 'Forgot Password?' to recover account.")
    }
}
  return (
    <View>
        <Formik
        initialValues={{email:'', password:'',}}
        onSubmit={(values) => {
            onLogin(values.email, values.password)
        }}
        validationSchema={LoginFormSchema}
        validateOnMount={true}>

            {({handleChange, handleBlur, handleSubmit, values, isValid}) => 
            <>
       <View style={[styles.inputs,
    {borderColor: values.email.length < 1 || Validator.validate(values.email)  ? 'black' : "red"}]}>
            <TextInput style={{padding: 15}}placeholder='Email' placeholderTextColor={'black'} onChangeText={handleChange('email')} onBlur={handleBlur('email')} value={values.email}/>
            
        </View>
     
        <View style={[styles.inputs,
    {borderColor: values.password.length < 1 || values.password.length>=8 ? 'black' : "red"}]}>
            <TextInput autoCorrect={false} secureTextEntry={true} style={{padding: 15}}placeholder='Password' placeholderTextColor={'black'}  onChangeText={handleChange('password')} onBlur={handleBlur('password')} value={values.password}/>
            
        </View>

       <TouchableOpacity onPress={handleSubmit} disabled={!isValid}>
        <View style={styles.button}>
            <Text style={{color:'white', padding:10, fontSize:20}}>Login</Text>
        </View>
        </TouchableOpacity> 
        <TouchableOpacity 
        onPress={() =>navigation.push('RegisterScreen')}
        style={{alignItems:'center', paddingTop:25}}>
            <Text style={styles.text}>Click Here To Create An Account</Text>
        </TouchableOpacity>
        <TouchableOpacity 
        onPress={() =>navigation.navigate('Forgot')}
        style={{alignItems:'center', paddingTop:25}}>
            <Text style={styles.text}>Forgot Password?</Text>
        </TouchableOpacity>
        </>
}
        </Formik>
    </View>
  )
}
const styles = StyleSheet.create({
    container: {

    },
    logo: {
        alignItems:"center",
        paddingTop:100,
        paddingBottom:50,
    },
    inputs:{
        backgroundColor:"#fafafafa",
        marginHorizontal: 30,
        borderColor:"black",
        borderWidth: 1,
        marginBottom:20,
        borderRadius:30
    },
    text:{
        color:"gray",
    },
    button: {
        backgroundColor:'orange',
        alignItems:'center',
        borderRadius:30,
        marginHorizontal:40,
        marginTop:20

    }
    
   
})

export default LoginForm
import { View, Text, Button } from 'react-native'
import React from 'react'
import { SafeAreaView } from 'react-native'
import { StyleSheet, TouchableOpacity, TextInput } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons';
import { useRoute } from '@react-navigation/native'
import firebase from '../firebase'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Divider } from 'react-native-elements'

const uploadPostSchema = Yup.object().shape({
    ename: Yup.string().max(50,'Workout name has reached character limit'),
    link: Yup.string().max(50,'Workout name has reached character limit'),
    rest: Yup.string().max(50,'Workout name has reached character limit'),
    reps: Yup.string().max(50,'Workout name has reached character limit'),
    tag: Yup.string().max(50,'Workout name has reached character limit')
})

const NewExerciseScreen = ({navigation}) => {
    const name = useRoute().params.name
    const email = useRoute().params.email
    const workoutId = useRoute().params.workoutId

    const Upload = (tag,ename,reps,rest,link)=>{

        firebase.firestore().collection('users').doc(email).collection('posts').doc(workoutId).collection('workoutNames').doc(name).collection('exercises')
        .add({
           tag:tag,
           ename:ename,
           reps:reps,
           rest:rest,
           link:link,
           createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          }).then(()=>navigation.goBack())
          
    }   
  return (
    <SafeAreaView>
          <Header navigation={navigation}/>
    
     <Formik
    initialValues={{tag:'', ename: '', reps:'',rest:'', link: '',}}
    onSubmit={(values) => {
     
      Upload(values.tag, values.ename, values.reps, values.rest, values.link)
    }}
    validationSchema={uploadPostSchema}
    validateOnMount={true}>
       
        {({ handleBlur, handleChange, handleSubmit, values, errors, isValid, push, remove}) => 
        <>
        <View style={{ margin: 20, justifyContent: 'space-between' }}>
            
        
        <TextInput 
        style={{fontSize: 20}} 
        placeholder= 'Tag (ie: A1, used for supersets)' 
        placeholderTextColor= 'black'
        onChangeText={handleChange('tag')}
        onBlur={handleBlur('tag')}
        value={values.tag}/>

         <Divider style={{marginVertical: 10}}/>
         <TextInput 
        style={{fontSize: 20}} 
        placeholder= 'Exercise Name' 
        placeholderTextColor= 'black'
        onChangeText={handleChange('ename')}
        onBlur={handleBlur('ename')}
        value={values.ename}/>
<Divider style={{marginVertical: 10}}/>
        <TextInput 
        style={{fontSize: 20}} 
        placeholder= 'Reps (ie: 4x8)' 
        placeholderTextColor= 'black'
        onChangeText={handleChange('reps')}
        onBlur={handleBlur('reps')}
        value={values.reps}/>

<Divider style={{marginVertical: 10}}/>  
        <TextInput 
        style={{fontSize: 20}} 
        placeholder= 'Rest ( in seconds)' 
        placeholderTextColor= 'black'
        onChangeText={handleChange('rest')}
        onBlur={handleBlur('rest')}
        value={values.rest}/>
<Divider style={{marginVertical: 10}}/>
        <TextInput 
        style={{fontSize: 20}} 
        placeholder= 'Link to how to complete exercise' 
        placeholderTextColor= 'black'
        onChangeText={handleChange('link')}
        onBlur={handleBlur('link')}
        value={values.link}/>



        </View>
     
        
          
          <Button onPress= {handleSubmit} title="Post" disabled={!isValid}/>
        
        </>
  }


    </Formik>
    </SafeAreaView>
  )
}
const Header = ( {navigation}) => (
    <View style={styles.headerContainer}>
            <TouchableOpacity>
                <Ionicons name="chevron-back-outline" size={30} color='black' onPress={()=> navigation.goBack()}></Ionicons>
            </TouchableOpacity>

            
            <Text style={styles.headerText}>Add Exercise</Text>
            
            <Text/>
    </View>
)

const styles = StyleSheet.create({
    container: {
        marginHorizontal: 10,
        
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent:'space-between',
        alignItems: 'center',
        padding: 10,
        borderBottomColor: 'lightgray',
        borderBottomWidth: 1,
        
    },
    headerText:{
        fontWeight: 'bold',
       marginRight: 25,
       

    }
})

export default NewExerciseScreen
import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SignedInStack } from './navigation';
import { SignedOutStack } from './navigation';
import firebase from './firebase';

export default class AuthNavigation extends Component {
  constructor(props){
    super(props);
    this.state = {
      loggedIn: false,
    }
  }
         componentDidMount(){
           firebase.auth().onAuthStateChanged(user => {
          if(!user){
            this.setState({
              loggedIn: false
            })
          }else{
            this.setState({
              loggedIn:true
            })
          }
        })}
  render() {
    const {loggedIn} = this.state;
    if(loggedIn){
      return(<SignedInStack/>)
    }else{
      return(<SignedOutStack/>)
    }
    
  }
}




// import { View, Text } from 'react-native'
// import React, { useEffect, useState } from 'react'
// import { SignedInStack, SignedOutStack } from './navigation'
// import {firebase} from './firebase'

// class AuthNavigation extends Component {
//         firebase.auth().onAuthStateChanged(user => {
//           if(!user){
//             this.setState({
//               loggedIn: false
//             })
//           }else{
//             this.setState({
//               loggedIn:true
//             })
//           }
//         }),[]

//         render() {
//      const {loggedIn} = this.state
//     if(!loggedIn){
//       return(<SignednStack/>)
//     }else{
//       return(<SignedOutStack/>)
//     }
//   }

// }

// export default AuthNavigation
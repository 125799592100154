import React from 'react'
import { SafeAreaView, Text , StyleSheet, View, TouchableOpacity} from 'react-native'
import BigPost from '../component/home/BigPost'
import { useRoute } from '@react-navigation/native'
import firebase from '../firebase'
import { useState, useEffect,  } from 'react'
import Ionicons from '@expo/vector-icons/Ionicons';

const BigPostScreen = ({navigation, post}) => {
  const workoutId = useRoute().params.workoutId
  const email = useRoute().params.email
  console.log(workoutId, email, 'pospdfkpafpa')
  const [show, setShow]= useState(null)

  useEffect(()=>{
      firebase.firestore()
  .collection("users")
  .doc(firebase.auth().currentUser.email)
  .collection("userFollowing")
  .doc(email)
  .onSnapshot((doc) => {
      if (doc.exists) {
          setShow(true)
          }else if (firebase.auth().currentUser.email === email){
              setShow(true)
          }else{
            
          }
     
})
  })
    return (
        <SafeAreaView>
                      {show === true ? (
                  
                   <BigPost navigation={navigation} {...{workoutId}} {...{email}}/>
                ):(
                  <View>
                     <Header navigation={navigation}></Header>
                    <Text style={{textAlign:'center', paddingTop:200, fontWeight:'bold', fontSize:20}}>Subscribe to See User Content</Text>
                    <Ionicons style={{padding:50, justifyContent:'center', alignSelf:'center'}} name="lock-closed-outline" size={150} color='black'></Ionicons>
                </View>
                )}      
        </SafeAreaView>
       )
     }


     const Header = ( {navigation, workoutId}) => (
      <View style={styles.headerContainer}>
              <TouchableOpacity>
                  <Ionicons name="chevron-back-outline" size={30} color='black' onPress={()=> navigation.goBack()}></Ionicons>
              </TouchableOpacity>
              <Text style={styles.headerText}></Text>
      </View>
  )

  
  const styles = StyleSheet.create({
      container: {
          
          
      },
      headerContainer: {
          flexDirection: 'row',
          justifyContent:'space-between',
          alignItems: 'center',
          padding: 10,
          borderBottomColor: 'lightgray',
          borderBottomWidth: 1,
      },
      headerText:{
          fontWeight: 'bold',
         marginRight: 30,
         
  
      },
      click:{
          borderBottomColor:"lightgray",
          borderBottomWidth: 1,
          
      },
      title:{
          fontSize:15,
          padding:15,
          paddingLeft:40
      },
      header:{
          fontSize:18,
          padding:20,
          color: 'white',
          textAlign:'center',
          fontWeight: 'bold'

          
      }
  })
export default BigPostScreen